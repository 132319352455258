import * as configs from '../../services/configs'

export const UPDATE_MAX_DUELS = 'configs/UPDATE_MAX_DUELS'
export const UPDATE_SKIP_DUELS = 'configs/UPDATE_SKIP_DUELS'

export const get = () => async dispatch => {
  const { maxDuels, skipDuels } = await configs.get()
  dispatch({ type: UPDATE_MAX_DUELS, maxDuels })
  dispatch({ type: UPDATE_SKIP_DUELS, skipDuels })
}

export const updateMaxDuels = maxDuels => async dispatch => {
  await configs.updateMaxDuels(maxDuels)
  dispatch({ type: UPDATE_MAX_DUELS, maxDuels })
}

export const updateSkipDuels = skipDuels => async dispatch => {
  await configs.updateSkipDuels(skipDuels)
  dispatch({ type: UPDATE_SKIP_DUELS, skipDuels })
}
