import { UPDATE_CHOOSABLES, UPDATE_AVATARS } from '../actions/choosables'

const initialState = {
  sexes: [],
  questions: [],
  qualities: [],
  activities: [],
  musics: [],
  places: [],
  avatars: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CHOOSABLES:
      return { ...state, ...action.choosables }
    case UPDATE_AVATARS:
      const { avatars } = action
      return { ...state, avatars }
    default:
      return state
  }
}

export default reducer
