import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as selectors from '../../../store/selectors'
import Loader from '../../../components/loader'
import Button from '../../../components/button'
import Card from '../../../components/card'
import Image from '../../../components/image'
import DisplayAvatars from '../../../components/avatars'
import * as Inputs from '../../../components/inputs'
import movies from './data/movies'
import meals from './data/meals'
import faker from 'faker'
import * as profiles from '../../../services/profiles'
import styles from './fake-user.module.css'
import strings from './fake-user.strings.json'

const randomInt = (limit: number) => {
  const value = Math.random() * limit
  return Math.floor(value)
}

const randomData = async (uid, choosables) => {
  const gender = randomInt(2)
  const firstName = faker.name.firstName(gender)
  const { sexes, musics, activities, places, qualities, questions, avatars } =
    choosables
  const sex = gender === 0 ? 'man' : 'woman'
  const favoriteMovie = movies[randomInt(movies.length)]
  const favoriteMeal = meals[randomInt(meals.length)]
  const favoriteMusic = musics[randomInt(musics.length)].id
  const favoriteActivity = activities[randomInt(activities.length)].id
  const favoritePlace = places[randomInt(places.length)].id
  const quality = qualities[randomInt(qualities.length)].id
  const searchedGender = sexes[randomInt(sexes.length)].id
  const lowAge = 18
  const highAge = 80
  const searchedQuality = qualities[randomInt(qualities.length)].id
  const selectedQuestion = questions[randomInt(questions.length)].id
  const avatar = avatars[randomInt(avatars.length)].id
  const birthday = faker.date.past(50, new Date(2000, 0, 1))
  const pics = []
  return {
    uid,
    firstName,
    sex,
    favoriteMovie,
    favoriteMeal,
    favoriteMusic,
    favoriteActivity,
    favoritePlace,
    quality,
    searchedGender,
    lowAge,
    highAge,
    searchedQuality,
    selectedQuestion,
    avatar,
    birthday,
    pics,
  }
}

const UploadPictures = ({ token, user, setUser }) => {
  const pics = user.pics ?? []
  return (
    <Card
      title={strings.upload.title}
      subtitle={strings.upload.subtitle}
      className={styles.uploadPicture}
    >
      <div className={styles.inputCardInside}>
        <Inputs.File
          multiple
          onChange={async files => {
            const result = await Promise.all(
              files.map(async file => {
                const reader = new FileReader()
                const promise = new Promise(resolve => {
                  reader.addEventListener('load', () => {
                    resolve(reader.result)
                  })
                })
                reader.readAsDataURL(file)
                const content = await promise
                const [dat, base64] = content.split(',')
                const type = dat.split(';')[0].split(':')[1]
                return profiles.pictures.upload(user.uid, base64, type)
              }),
            )
            setUser(u => ({ ...u, pics: [...u.pics, ...result] }))
          }}
        />
        <div className={styles.picturesPreview}>
          {pics.length === 0 ? (
            <div className={styles.picturePlaceholder} />
          ) : (
            pics.map(pic => (
              <Image
                key={pic}
                Loader={<Loader />}
                url={`/pictures/${user.uid}/${pic}`}
                headers={{ Authorization: `Bearer ${token}` }}
                className={styles.picturePlaceholder}
                imgClassName={styles.profilePicture}
                alt=""
              />
            ))
          )}
        </div>
      </div>
    </Card>
  )
}

const CustomUser = ({ token, user, setUser, choosables, setLoading }) => {
  const up = field => value => setUser(u => ({ ...u, [field]: value }))
  const ge = field => user[field] ?? ''
  const randomizeUser = async uid => {
    const newUser = await randomData(uid ?? user.uid, choosables)
    setUser(() => {
      if (uid) {
        return newUser
      } else {
        return { ...newUser, pics: user.pics }
      }
    })
  }
  return (
    <div className={styles.editor}>
      <div className={styles.buttonsWrap}>
        <Button
          title={strings.buttons.save}
          primary
          disabled={user.pics?.length === 0 || !user.avatar}
          onClick={async () => {
            try {
              setLoading(true)
              const birthday = user.birthday.toISOString()
              const searchedGender = [user.searchedGender]
              await profiles.createNewUser({
                ...user,
                birthday,
                searchedGender,
              })
              toast.success(strings.toasts.success)
              const uid = await profiles.newUid()
              await randomizeUser(uid)
            } catch (error) {
              toast.error(strings.toasts.error)
            } finally {
              setLoading(false)
            }
          }}
        />
        <Button
          title={strings.buttons.randomize}
          secondary
          onClick={() => randomizeUser()}
        />
      </div>
      <UploadPictures token={token} user={user} setUser={setUser} />
      <DisplayAvatars
        choose
        avatars={choosables.avatars}
        className={styles.dogsAvatars}
        selected={ge('avatar')}
        onClick={up('avatar')}
      />
      <Card
        title={strings.firstName.title}
        subtitle={strings.firstName.subtitle}
      >
        <Inputs.Input value={ge('firstName')} onChange={up('firstName')} />
      </Card>
      <Card title={strings.birthday.title} subtitle={strings.birthday.subtitle}>
        <Inputs.Input
          type="date"
          value={ge('birthday')}
          onChange={up('birthday')}
        />
      </Card>
      <Card title={strings.sex.title} subtitle={strings.sex.subtitle}>
        <Inputs.Select
          value={ge('sex')}
          values={choosables.sexes}
          onChange={up('sex')}
        />
      </Card>
      <Card title={strings.music.title} subtitle={strings.music.subtitle}>
        <Inputs.Select
          value={ge('favoriteMusic')}
          values={choosables.musics}
          onChange={up('favoriteMusic')}
        />
      </Card>
      <Card title={strings.activity.title} subtitle={strings.activity.subtitle}>
        <Inputs.Select
          value={ge('favoriteActivity')}
          values={choosables.activities}
          onChange={up('favoriteActivity')}
        />
      </Card>
      <Card title={strings.place.title} subtitle={strings.place.subtitle}>
        <Inputs.Select
          value={ge('favoritePlace')}
          values={choosables.places}
          onChange={up('favoritePlace')}
        />
      </Card>
      <Card title={strings.quality.title} subtitle={strings.quality.subtitle}>
        <Inputs.Select
          value={ge('quality')}
          values={choosables.qualities}
          onChange={up('quality')}
        />
      </Card>
      <Card
        title={strings.searchedSex.title}
        subtitle={strings.searchedSex.subtitle}
      >
        <Inputs.Select
          value={ge('searchedGender')}
          values={choosables.sexes}
          onChange={up('searchedGender')}
        />
      </Card>
      <Card title={strings.lowAge.title} subtitle={strings.lowAge.subtitle}>
        <Inputs.Input
          type="number"
          value={ge('lowAge')}
          onChange={up('lowAge')}
        />
      </Card>
      <Card title={strings.highAge.title} subtitle={strings.highAge.subtitle}>
        <Inputs.Input
          type="number"
          value={ge('highAge')}
          onChange={up('highAge')}
        />
      </Card>
      <Card
        title={strings.searchedQuality.title}
        subtitle={strings.searchedQuality.subtitle}
      >
        <Inputs.Select
          value={ge('searchedQuality')}
          values={choosables.qualities}
          onChange={up('searchedQuality')}
        />
      </Card>
      <Card title={strings.question.title} subtitle={strings.question.subtitle}>
        <Inputs.Select
          value={ge('selectedQuestion')}
          values={choosables.questions}
          onChange={up('selectedQuestion')}
        />
      </Card>
    </div>
  )
}

const FakeUser = () => {
  const choosables = useSelector(selectors.choosables)
  const token = useSelector(selectors.token)
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (choosables.questions.length > 0 && choosables.avatars.length > 0) {
      profiles
        .newUid()
        .then(uid => randomData(uid, choosables))
        .then(setUser)
        .then(() => setLoading(false))
    }
  }, [choosables])
  if (loading) {
    return (
      <Card className={styles.loaderCard} center>
        <Loader />
      </Card>
    )
  } else {
    return (
      <div className={styles.main}>
        <CustomUser
          token={token}
          user={user}
          setUser={setUser}
          choosables={choosables}
          setLoading={setLoading}
        />
      </div>
    )
  }
}

export default FakeUser
