import firebase from './firebase'

const genericFetch = async (endpoint, method, options = {}) => {
  const token = await firebase.auth().currentUser?.getIdToken()
  if (token) {
    const headers = { ...options.headers, Authorization: `Bearer ${token}` }
    const res = await fetch(endpoint, { ...options, method, headers })
    return res.json()
  } else {
    return null
  }
}

export const get = async (endpoint, options = {}) => {
  return genericFetch(endpoint, 'GET', options)
}

export const post = async (endpoint, body) => {
  const headers = { 'Content-Type': 'application/json' }
  return genericFetch(endpoint, 'POST', { headers, body: JSON.stringify(body) })
}

export const del = async (endpoint, options = {}) => {
  return genericFetch(endpoint, 'DELETE', options)
}
