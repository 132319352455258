import { createContext, useRef, useState, useEffect, useContext } from 'react'

const Size = createContext()

export const useNavbarHeight = pathname => {
  const titleRef = useRef()
  const subtitleRef = useRef()
  const [navbarHeight, setNavbarHeight] = useState(90)
  const [titleHeight, setTitleHeight] = useState(35)
  const [subtitleHeight, setSubTitleHeight] = useState(35)
  useEffect(() => {
    const node = document.getElementById('navbar')
    const dimensions = node.getBoundingClientRect()
    setNavbarHeight(dimensions.height)
    if (titleRef.current) {
      const { height } = titleRef.current.getBoundingClientRect()
      setTitleHeight(height)
    }
    if (subtitleRef.current) {
      const { height } = subtitleRef.current.getBoundingClientRect()
      setSubTitleHeight(height)
    }
  }, [pathname])
  const titleTop = navbarHeight
  const subtitleTop = navbarHeight + titleHeight
  const totalHeight = navbarHeight + titleHeight + subtitleHeight
  return {
    navbarHeight,
    titleHeight,
    subtitleHeight,
    titleTop,
    subtitleTop,
    titleRef,
    subtitleRef,
    totalHeight,
  }
}

export const Provider = Size.Provider

export const useBodySize = padding => {
  const size = useContext(Size)
  const values = ['100vh', `${size}px`, `${padding}px`].filter(t => Boolean(t))
  return `calc(${values.join(' - ')})`
}
