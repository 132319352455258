import { createElement } from 'react'
import { Link } from 'react-router-dom'
import styles from './button.module.css'

const defaultHandler = (title, { submit, to, nav }) => () => {
  if (!submit) {
    if (nav) {
      if (!to) {
        console.warn('to not provided')
      }
    } else {
      console.warn(`${title} onClick not implemented…`)
    }
  }
}

const selectClassName = props => {
  if (props.primary) {
    return styles.primaryButton
  } else if (props.secondary) {
    return styles.secondaryButton
  } else if (props.warning) {
    return styles.warningButton
  } else if (props.info) {
    return styles.infoButton
  } else if (props.shutdown) {
    return `${styles.defaultButton} ${styles.shutdown}`
  } else {
    return styles.defaultButton
  }
}

const Icon = props => (
  <img src={props.icon} alt={props.iconAlt} className={styles.icon} />
)

const Button = ({ style, title, disabled, ...props }) => {
  const onClick = props.onClick ?? defaultHandler(title, props)
  const clName = selectClassName(props)
  const className = [props.className ?? [], clName].flat().join(' ')
  const icon = props.icon && <Icon {...props} />
  const p = {
    className,
    style,
    onClick,
    disabled,
    to: props.to,
    type: props.submit ? 'submit' : 'button',
  }
  if (props.nav) {
    return createElement(Link, p, title, icon)
  } else {
    return createElement('button', p, title, icon)
  }
}

export default Button
