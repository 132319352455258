import { UPDATE_PROFILE } from '../actions/profiles'

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE:
      const { profile } = action
      return { ...state, [profile.id]: profile }
    default:
      return state
  }
}

export default reducer
