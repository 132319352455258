import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styles from './home.module.css'
import SignIn from '../../components/sign_in'

const useUserRedirect = ({ history }) => {
  const user = useSelector(store => store.settings.user)
  useEffect(() => {
    if (user && user !== 'loading') {
      history.replace('/duels')
    }
  })
}

const Home = ({ history }) => {
  useUserRedirect({ history })
  return (
    <div className={styles.home}>
      <div className={styles.center}>
        <SignIn />
      </div>
    </div>
  )
}

export default Home
