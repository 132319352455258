import {
  UPDATE_USER,
  UPDATE_TOKEN,
  UPDATE_MODERATORS,
  UPDATE_USERS,
  DISABLE_USER,
} from '../actions/settings'

const initialState = {
  user: 'loading',
  token: null,
  moderators: [],
  users: [],
  page: 0,
  disabled: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      const { user } = action
      return { ...state, user }
    case UPDATE_TOKEN:
      const { token } = action
      return { ...state, token }
    case UPDATE_MODERATORS:
      const { moderators } = action
      return { ...state, moderators }
    case UPDATE_USERS:
      const { page, disabled } = action
      const users = [...state.users, ...action.users]
      return { ...state, users, page, disabled }
    case DISABLE_USER: {
      const { uid } = action
      const users = state.users.map(user => {
        if (user.profile.id === uid) {
          const stats = { ...user.stats, disabled: true }
          const metadata = { ...user.metadata, disabled: true }
          return { ...user, stats, metadata }
        }
        return user
      })
      return { ...state, users }
    }
    default:
      return state
  }
}

export default reducer
