import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from '../../../components/table'
import Button from '../../../components/button'
import Spacer from '../../../components/spacer'
import { useBodySize } from '../../../helpers/body'
import { useWidth } from '../../../helpers/window'
import * as selectors from '../../../store/selectors'
import * as actions from '../../../store/actions/likes'
import strings from './likes.strings.json'
import styles from './likes.module.css'

const fields = ['id', 'count', 'lastSeen', 'action']

const Postpone = ({ id, dispatch }) => {
  const onClick = async event => {
    event.preventDefault()
    await dispatch(actions.postpone(id))
  }
  return <Button title={strings.buttons.postpone} secondary onClick={onClick} />
}

const searchFilter = search => {
  const lower = search.toLowerCase()
  const messagesReduce = (acc, message) => {
    const content = message.content.toLowerCase()
    return acc || content.includes(lower) || message.author.includes(search)
  }
  return like =>
    search.length === 0 ||
    like.id.includes(search) ||
    like.messages.reduce(messagesReduce, false)
}

const initialMessagesLS = () => {
  const item = localStorage.getItem('courtisan-with-messages')
  const parsed = JSON.parse(item)
  return parsed ?? true
}

const initialFakesLS = () => {
  const item = localStorage.getItem('courtisan-with-fakes')
  const parsed = JSON.parse(item)
  return parsed ?? true
}

const Likes = () => {
  const search = useSelector(selectors.search)
  const [withMessages, setWithMessages] = useState(initialMessagesLS)
  const [withFakes, setWithFakes] = useState(initialFakesLS)
  const bodySize = useBodySize(24)
  const likes = useSelector(selectors.likes)
  const dispatch = useDispatch()
  useEffect(() => {
    const value = JSON.stringify(withMessages)
    localStorage.setItem('courtisan-with-messages', value)
  }, [withMessages])
  useEffect(() => {
    const value = JSON.stringify(withFakes)
    localStorage.setItem('courtisan-with-fakes', value)
  }, [withFakes])
  useEffect(() => {
    if (likes.length === 0) dispatch(actions.get())
  }, [dispatch, likes])
  const width = useWidth()
  const style = width > 450 ? { maxHeight: bodySize } : {}
  return (
    <div className={styles.layout} style={style}>
      <div className={styles.buttonsRow}>
        <Button
          secondary
          onClick={() => setWithMessages(m => !m)}
          title={
            withMessages
              ? strings.buttons.withoutMessages
              : strings.buttons.withMessages
          }
        />
        <Spacer size={12} />
        <Button
          primary
          onClick={() => setWithFakes(m => !m)}
          title={
            withFakes ? strings.buttons.withoutFakes : strings.buttons.withFakes
          }
        />
      </div>
      <Table
        fields={fields}
        data={likes
          .filter(l => !withMessages || l.count > 1)
          .filter(l => !withFakes || !l.fake)
          .filter(searchFilter(search))
          .map(l => {
            const action = <Postpone id={l.id} dispatch={dispatch} />
            const to = `/likes/${l.id}`
            return { ...l, action, to }
          })}
      />
    </div>
  )
}

export default Likes
