import {
  UPDATE_LIKES,
  POSTPONE_USER,
  REMOVE_MESSAGES,
  DECREMENT_COUNT,
} from '../actions/likes'

const initialState = []

const removeMessages = (state, action) => {
  const { id, messages } = action
  return state.map(like => {
    if (like.id === id) {
      const count = like.count - messages.length
      const m = like.messages.filter(({ author }) => {
        return !messages.find(m_ => m_.author === author)
      })
      return { ...like, messages: m, count }
    }
    return like
  })
}

const decrementCount = (state, { id, competitors }) => {
  return state.map(like => {
    if (like.id === id) {
      const count = like.count - competitors.length
      const messages = like.messages.filter(m => {
        const id = m.author
        return !competitors.includes(id)
      })
      return { ...like, count, messages }
    } else {
      return like
    }
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_MESSAGES:
      return removeMessages(state, action)
    case UPDATE_LIKES:
      return action.likes
    case POSTPONE_USER:
      return state.filter(l => l.id !== action.id)
    case DECREMENT_COUNT:
      return decrementCount(state, action)
    default:
      return state
  }
}

export default reducer
