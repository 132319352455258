import { UPDATE_MESSAGES, UPDATE_CONVERSATIONS } from '../actions/conversations'

const initialState = {
  conversations: {},
  messages: {},
}

const updateConversations = (state, { conversations }) => {
  return { ...state, conversations }
}

const updateMessages = (state, action) => {
  const messages = { ...state.messages, [action.cid]: action.messages }
  return { ...state, messages }
}

const conversationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONVERSATIONS:
      return updateConversations(state, action)
    case UPDATE_MESSAGES:
      return updateMessages(state, action)
    default:
      return state
  }
}

export default conversationsReducer
