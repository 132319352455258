import { Fragment, useRef, useEffect, useCallback, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Card from '../card'
import Spacer from '../spacer'
import { useWidth } from '../../helpers/window'
import styles from './table.module.css'

const DateFormat = ({ date }) => {
  return date.toLocaleString('fr-FR', {
    year: 'numeric',
    day: 'numeric',
    month: 'numeric',
  })
}

const BoolFormat = ({ bool }) => {
  const cl = bool ? styles.enabled : styles.disabled
  const content = bool ? 'True' : 'False'
  return <div className={cl}>{content}</div>
}

const Div = props => <div {...props} />

const RenderContent = ({ data }) => {
  if (data instanceof Date) {
    return <DateFormat date={data} />
  } else if (typeof data === 'boolean') {
    return <BoolFormat bool={data} />
  } else {
    return data ?? null
  }
}

const selectStyle = ({ key, data }) => {
  if (key.includes('id')) {
    return styles.uid
  } else if (data instanceof Date) {
    return styles.email
  } else if (typeof data === 'boolean') {
    return styles.disWrap
  } else {
    return styles.name
  }
}

const Row = ({ fields, data, gridTemplateColumns }) => {
  const handler = data.onClick || (() => {})
  const Element = data.to ? Link : Div
  // prettier-ignore
  const renderItem = useCallback(key => {
    const dat = data[key]
    const cl = selectStyle({ key, data: dat })
    return (
      <div key={key} className={cl}>
        <RenderContent data={dat} />
      </div>
    )
  }, [data])
  return (
    <Card className={styles.noRound} overflow>
      <Element
        className={styles.infoWrapper}
        style={{ gridTemplateColumns }}
        to={data.to}
        onClick={handler}
      >
        {fields.map(renderItem)}
      </Element>
    </Card>
  )
}

const uncamelize = str => {
  return str.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`)
}

const renderFieldName = (field, className) => {
  const textTransform = field.includes('id') ? 'uppercase' : 'capitalize'
  const cl = className || styles.email
  return (
    <div className={cl} style={{ textTransform }} key={field}>
      {uncamelize(field)}
    </div>
  )
}

const RenderFieldNames = ({ fields, gridTemplateColumns }) => (
  <Card area="header" className={styles.headerWrapper} overflow>
    <div className={styles.header} style={{ gridTemplateColumns }}>
      {fields.map(renderFieldName)}
    </div>
  </Card>
)

const useRestoreScroll = fields => {
  const history = useHistory()
  const key = fields.join('/')
  const { pathname } = history.location
  const bodyRef = useRef()
  const sessionKey = [pathname, key].join('/')
  // prettier-ignore
  const onScroll = useCallback(event => {
    const { scrollTop } = event.target
    sessionStorage.setItem(sessionKey, scrollTop)
  }, [sessionKey])
  useEffect(() => {
    if (bodyRef.current && history.action === 'POP') {
      const initialScroll = sessionStorage.getItem(sessionKey) || 0
      bodyRef.current.scrollTop = initialScroll
    }
  }, [sessionKey, history])
  return { onScroll, bodyRef }
}

const FullTable = ({ area, data, fields }) => {
  const { onScroll, bodyRef } = useRestoreScroll(fields)
  const common = useMemo(() => {
    const gridTemplateColumns = `repeat(${fields.length}, 1fr)`
    return { fields, gridTemplateColumns }
  }, [fields])
  // prettier-ignore
  const renderItem = useCallback(dat => {
    return <Row data={dat} key={dat.id} {...common} />
  }, [common])
  return (
    <div className={styles.layout} style={{ gridArea: area }}>
      <RenderFieldNames {...common} />
      <div ref={bodyRef} className={styles.body} onScroll={onScroll}>
        {data.map(renderItem)}
        <div className={styles.spacer} />
      </div>
    </div>
  )
}

const CardsTable = ({ area, data, renderCard }) => {
  return (
    <div className={styles.layout} style={{ gridArea: area }}>
      <div className={styles.body}>{data.map(renderCard)}</div>
    </div>
  )
}

const renderCard = (fields, data) => {
  const { to } = data
  const handler = data.onClick || (() => {})
  const Elem = to ? Link : Div
  return (
    <Fragment key={data.id}>
      <Elem to={to} onClick={handler} className={styles.cardLinkWrapper}>
        <Card>
          <div className={styles.cardBody}>
            {fields.map(field => (
              <div className={styles.cardSectionWrapper} key={field}>
                <div className={styles.cardSection}>
                  {renderFieldName(field, styles.cardField)}
                  <div className={styles.cardAction}>
                    <RenderContent data={data[field]} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </Elem>
      <Spacer size={24} />
    </Fragment>
  )
}

const Table = ({ area, fields, data }) => {
  const width = useWidth()
  const renderCard_ = useCallback(d => renderCard(fields, d), [fields])
  if (width <= 450) {
    return <CardsTable area={area} data={data} renderCard={renderCard_} />
  } else {
    return <FullTable area={area} data={data} fields={fields} />
  }
}

export default Table
