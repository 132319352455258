import { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import Card from '../../../components/card'
import Button from '../../../components/button'
import { File } from '../../../components/inputs'
import Loader from '../../../components/loader'
import Spacer from '../../../components/spacer'
import DisplayAvatars from '../../../components/avatars'
import * as selectors from '../../../store/selectors'
import * as actions from '../../../store/actions/choosables'
import strings from './avatars.strings.json'
import styles from './avatars.module.css'

const LocalPreviews = ({ localPicture }) => {
  // eslint-disable-next-line
  const Node = localPicture ? p => <img {...p} /> : p => <div {...p} />
  const source = localPicture ? URL.createObjectURL(localPicture) : null
  return (
    <div className={styles.previewCard}>
      <Node alt="white" className={styles.avatar} src={source} />
      <Node alt="blue" className={styles.avatarBlue} src={source} />
      <Node alt="red" className={styles.avatarRed} src={source} />
    </div>
  )
}

const UploadCard = ({ refresh }) => {
  const [localPicture, setLocalPicture] = useState()
  const [category, setCategory] = useState()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const uploadPicture = async () => {
    try {
      if (localPicture) {
        setLoading(true)
        await dispatch(actions.addAvatar(category, localPicture))
        setLocalPicture()
        setCategory()
        await refresh()
        toast.success(strings.toast.success)
      }
    } catch (error) {
      toast.error(strings.toast.error)
    } finally {
      setLoading(false)
    }
  }
  const onDrop = ([file]) => {
    setLocalPicture(file)
    setCategory('cat')
  }
  return (
    <Card title={strings.upload.title} subtitle={strings.upload.subtitle}>
      {loading ? (
        <div className={styles.center}>
          <Loader />
        </div>
      ) : (
        <div className={styles.files}>
          <div className={styles.inputTitle}>{strings.upload.input}</div>
          <File className={styles.fileInput} onChange={onDrop} />
          <div className={styles.previewTitle}>{strings.upload.preview}</div>
          <LocalPreviews localPicture={localPicture} />
          <div className={styles.categoryTitle}>{strings.upload.category}</div>
          <div className={styles.categoryCard}>
            <Button
              title={strings.upload.cat}
              primary={category === 'cat'}
              shutdown={category !== 'cat'}
              onClick={() => setCategory('cat')}
              disabled={!localPicture}
            />
            <Spacer size={12} />
            <Button
              title={strings.upload.dog}
              primary={category === 'dog'}
              shutdown={category !== 'dog'}
              onClick={() => setCategory('dog')}
              disabled={!localPicture}
            />
          </div>
          <div className={styles.buttons}>
            <Button
              title={strings.upload.validate}
              primary
              disabled={!localPicture}
              onClick={uploadPicture}
              className={styles.ctaButton}
            />
            <Spacer size={12} />
            <Button
              title={strings.upload.reset}
              secondary
              disabled={!localPicture}
              onClick={() => {
                setLocalPicture()
                setCategory()
              }}
              className={styles.ctaButton}
            />
          </div>
        </div>
      )}
    </Card>
  )
}

const BatchUpload = ({ refresh }) => {
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState()
  const [files, setFiles] = useState()
  const dispatch = useDispatch()
  const uploadPicture = async () => {
    try {
      if (files) {
        setLoading(true)
        const promises = files.map(file =>
          dispatch(actions.addAvatar(category, file)),
        )
        await Promise.all(promises)
        setFiles()
        setCategory()
        await refresh()
        toast.success(strings.toast.success)
      }
    } catch (error) {
      toast.error(strings.toast.error)
    } finally {
      setLoading(false)
    }
  }
  const onDrop = files => {
    setFiles(files)
    setCategory('cat')
  }
  return (
    <Card title={strings.multi.title} subtitle={strings.multi.subtitle}>
      {loading ? (
        <div className={styles.center}>
          <Loader />
        </div>
      ) : (
        <div className={styles.multipleFiles}>
          <div className={styles.inputTitle}>{strings.upload.input}</div>
          {files ? (
            <div className={styles.fileInputPictures}>
              {files.map((file, index) => {
                const source = URL.createObjectURL(file)
                return (
                  <img
                    key={index}
                    src={source}
                    alt=""
                    className={styles.avatar}
                  />
                )
              })}
            </div>
          ) : (
            <File multiple className={styles.fileInput} onChange={onDrop} />
          )}
          <div className={styles.categoryTitle}>{strings.upload.category}</div>
          <div className={styles.categoryCard}>
            <Button
              title={strings.upload.cat}
              primary={category === 'cat'}
              shutdown={category !== 'cat'}
              onClick={() => setCategory('cat')}
            />
            <Spacer size={12} />
            <Button
              title={strings.upload.dog}
              primary={category === 'dog'}
              shutdown={category !== 'dog'}
              onClick={() => setCategory('dog')}
            />
          </div>
          <div className={styles.buttons}>
            <Button
              title={strings.upload.validate}
              primary
              disabled={!files}
              onClick={uploadPicture}
              className={styles.ctaButton}
            />
            <Spacer size={12} />
            <Button
              title={strings.upload.reset}
              secondary
              disabled={!files}
              onClick={() => {
                setFiles()
                setCategory()
              }}
              className={styles.ctaButton}
            />
          </div>
        </div>
      )}
    </Card>
  )
}

const Avatars = () => {
  const dispatch = useDispatch()
  const refreshAvatars = useCallback(async () => {
    await dispatch(actions.avatars())
  }, [dispatch])
  const { avatars } = useSelector(selectors.choosables)
  useEffect(() => refreshAvatars(), [refreshAvatars])
  const dogs = avatars.filter(({ category }) => category === 'dog')
  const cats = avatars.filter(({ category }) => category === 'cat')
  return (
    <div className={styles.layout}>
      <UploadCard refresh={refreshAvatars} />
      <Spacer size={24} />
      <BatchUpload refresh={refreshAvatars} />
      <Spacer size={24} />
      <DisplayAvatars dog avatars={dogs} />
      <Spacer size={24} />
      <DisplayAvatars cat avatars={cats} />
    </div>
  )
}

export default Avatars
