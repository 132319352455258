import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as selectors from '../../../store/selectors'
import Card from '../../../components/card'
import Image from '../../../components/image'
import Button from '../../../components/button'
import Spacer from '../../../components/spacer'
import * as profiles from '../../../services/profiles'
import * as supports from '../../../services/supports'
import styles from './support.module.css'
import strings from './support.strings.json'

const formatOptions = {
  month: 'long',
  day: '2-digit',
  year: 'numeric',
}

const toDateString = date => date.toLocaleString(undefined, formatOptions)
const capitalizeType = type => {
  const [first, ...rest] = type.replace(/-/g, ' ')
  return [first.toUpperCase(), ...rest].join('')
}

const Picture = ({ token, uid, pic }) => (
  <Image
    url={`/pictures/${uid}/${pic}`}
    headers={{ Authorization: `Bearer ${token}` }}
    imgClassName={styles.imgClassName}
    className={styles.imageWrapper}
  />
)

const Param = ({ title, content }) => (
  <div className={styles.paramWrapper}>
    <div className={styles.paramTitle}>{title}</div>
    <div className={styles.paramContent}>{content}</div>
  </div>
)

const UserDescription = ({ ask, token, ...props }) => {
  const { birthday, pics, favoriteMovie, favoriteMeal, firstName } = props
  const [profilePic, ...rest] = pics
  const areMorePictures = rest.length > 0
  const copyID = () => navigator.clipboard.writeText(props.id)
  return (
    <div className={styles.cardProfiles}>
      <div className={styles.profileRow}>
        <div className={styles.infoRow}>
          <div className={styles.descriptor}>
            {ask ? strings.ask : strings.problem}
            <div onClick={copyID} className={styles.id}>
              #{props.id}
            </div>
          </div>
          <Param title="🙂" content={firstName} />
          {birthday && <Param title="🎂" content={toDateString(birthday)} />}
          <Param title="🍿" content={favoriteMovie} />
          <Param title="🍣" content={favoriteMeal} />
        </div>
        <Picture token={token} uid={props.id} pic={profilePic} />
      </div>
      {areMorePictures && <Spacer size={12} />}
      {areMorePictures && (
        <div className={styles.descriptor}>{strings.pictures}</div>
      )}
      {areMorePictures && (
        <div className={styles.picturesWrap}>
          {rest.map(pic => (
            <div className={styles.carouselPicture} key={pic}>
              <Picture token={token} uid={props.id} pic={pic} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const useSupport = support => {
  const ask = getAsk(support)
  const problem = getProblem(support)
  const [askProblem, setAskProblem] = useState({ ask: null, problem: null })
  useEffect(() => {
    const toProm = async i => (i ? profiles.get(i) : null)
    const promises = Promise.all([toProm(ask), toProm(problem)])
    promises.then(([ask, problem]) => setAskProblem({ ask, problem }))
  }, [ask, problem])
  return askProblem
}

const getAsk = support => (support.type !== 'web-support' ? support.uid : null)
const getProblem = support => {
  if (support.type === 'support-request') {
    return support.problem
  } else if (support.type === 'duel-report') {
    return support.blocked
  } else {
    return null
  }
}

const SupportCard = ({ token, remove, support, add }) => {
  const { id, type, createdAt } = support
  const { ask, problem } = useSupport(support)
  const value = support.text || support.content
  const subtitle = `${capitalizeType(type)}, ${toDateString(createdAt)}`
  const onClick = async () => {
    await supports.archive(id).then(remove)
    const onClick = () => supports.unarchive(id).then(add)
    toast.success(strings.successToast, { onClick })
  }
  return (
    <Card title={`#${id}`} subtitle={subtitle}>
      {value && <Spacer size={24} />}
      {value && (
        <div className={styles.cardProfiles}>
          <div className={styles.descriptor}>{strings.message}</div>
          <div className={styles.paramContent}>{value}</div>
        </div>
      )}
      {ask && <Spacer size={24} />}
      {ask && <UserDescription token={token} ask {...ask} />}
      {problem && <Spacer size={24} />}
      {problem && <UserDescription token={token} {...problem} />}
      <Spacer size={24} />
      <div style={{ flex: 1 }} />
      <Button title="Archive" primary onClick={onClick} />
    </Card>
  )
}

const Support = () => {
  const token = useSelector(selectors.token)
  const [supports_, setSupports_] = useState([])
  useEffect(() => {
    supports.all().then(setSupports_)
  }, [])
  return (
    <div className={styles.grid}>
      {supports_.map(support => {
        const { id } = support
        const remove = () => setSupports_(sups => sups.filter(s => s.id !== id))
        const add = () => setSupports_(supports_ => [...supports_, support])
        return (
          <SupportCard
            key={id}
            token={token}
            support={support}
            remove={remove}
            add={add}
          />
        )
      })}
    </div>
  )
}

export default Support
