import { useState, useEffect } from 'react'

const handleBlob = async ({ res, setImage, running }) => {
  const blob = await res.blob()
  running.value && setImage(URL.createObjectURL(blob))
}

const handleError = async params => {
  const { res, onError, ErrorPlaceholder, setImage, running } = params
  const { status, statusText } = res
  const body = await res.text()
  const isError = onError && onError instanceof Function
  if (isError) running.value && onError({ status, statusText, body })
  running.value && setImage(ErrorPlaceholder)
}

const fetchImage = async props => {
  const { setImage, url, onError, ErrorPlaceholder, method, running } = props
  if (url) {
    const headers = props.headers || {}
    const res = await fetch(url, { method, headers })
    const common = { res, setImage, running }
    await (res.ok
      ? handleBlob(common)
      : handleError({ onError, ErrorPlaceholder, ...common }))
  } else {
    throw new Error()
  }
}

const useFetchImage = props => {
  const { url, headers, ErrorPlaceholder, onError, method } = props
  const [picture, setImage] = useState()
  useEffect(() => {
    const running = { value: true }
    const fetcher = { headers, method, url }
    const params = { ...fetcher, setImage, onError, ErrorPlaceholder, running }
    fetchImage(params).catch(() => running.value && setImage(ErrorPlaceholder))
    return () => (running.value = false)
  }, [url, headers, ErrorPlaceholder, onError, setImage, method])
  return picture
}

const Img = ({ className, imgClassName, picture, alt }) => (
  <div className={className}>
    <img className={imgClassName} src={picture} alt={alt} />
  </div>
)

const Image = props => {
  const { url, headers, ErrorPlaceholder, onError } = props
  const picture = useFetchImage({ url, headers, ErrorPlaceholder, onError })
  if (url && picture) {
    return <Img picture={picture} {...props} />
  } else if (url && !picture) {
    return props.Loader || null
  } else {
    return props.ErrorPlaceholder || null
  }
}

export default Image
