import { combineReducers } from 'redux'
import duelsReducer from './duels'
import settingsReducer from './settings'
import choosablesReducer from './choosables'
import profilesReducer from './profiles'
import miscellaneousReducer from './miscellaneous'
import conversationsReducer from './conversations'
import configsReducer from './configs'
import likesReducer from './likes'

export default combineReducers({
  duels: duelsReducer,
  settings: settingsReducer,
  choosables: choosablesReducer,
  profiles: profilesReducer,
  miscellaneous: miscellaneousReducer,
  conversations: conversationsReducer,
  configs: configsReducer,
  likes: likesReducer,
})
