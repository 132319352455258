import firebase from '../firebase'
import * as archives from './archives'

export const likes = () => firebase.firestore().collection('likes')
export const like = id => likes().doc(id)

export const all = async () => {
  const { docs } = await likes().orderBy('count', 'desc').limit(1000).get()
  return docs.map(doc => {
    const data = doc.data()
    const lastSeen = data.lastSeen.toDate()
    const fake = data.fake ?? false
    const messages = data.messages.map(message => {
      const createdAt = message.createdAt.toDate()
      return { ...message, createdAt }
    })
    return { id: doc.id, ...data, messages, lastSeen, fake }
  })
}

const addToRejectedMessages = uid => message => {
  archives.add({ ...message, uid, status: 'rejected', type: 'like' })
}

export const removeMessages = async (uid, messages) => {
  await Promise.all(messages.map(addToRejectedMessages(uid)))
  await like(uid).update({
    count: firebase.firestore.FieldValue.increment(-messages.length),
    messages: firebase.firestore.FieldValue.arrayRemove(...messages),
  })
}
