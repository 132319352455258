import { useState, useEffect } from 'react'

export const useWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const fun = () => setWidth(window.innerWidth)
    window.addEventListener('resize', fun)
    return () => window.removeEventListener('resize', fun)
  }, [])
  return width
}
