import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions/miscellaneous'
import * as selectors from '../../store/selectors'
import styles from './navbar.module.css'

const forbiddenPaths = [
  '/choosables',
  '/moderators',
  '/manage-avatars',
  '/duels/',
]

const NavBar = ({ onChange }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = history.location
  const input = useRef()
  // eslint-disable-next-line
  const [_, setActualHistory] = useState(pathname)
  const search = useSelector(selectors.search)
  const disabled = forbiddenPaths.reduce((acc, val) => {
    return acc || pathname.startsWith(val)
  }, false)
  useEffect(() => {
    dispatch(actions.updateSearch(''))
    setActualHistory(pathname)
  }, [pathname, dispatch])
  useEffect(() => {
    const listener = event => {
      const { ctrlKey, metaKey, keyCode } = event
      if ((ctrlKey || metaKey) && keyCode === 83) {
        event.preventDefault()
        input.current.focus()
      }
    }
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [])
  return (
    <label className={styles.navbar} id="navbar">
      <input
        ref={input}
        value={search}
        className={styles.search}
        placeholder="Search…"
        onChange={event => dispatch(actions.updateSearch(event.target.value))}
        disabled={disabled}
      />
    </label>
  )
}

export default NavBar
