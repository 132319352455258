import * as http from '../http'

export const LOOKING_FOR_CHALLENGER = 'looking-for-challenger'
export const STARTED = 'started'
export const DELIBERATION_IN_PROGRESS = 'deliberation-in-progress'
export const ENDED = 'ended'

const toMessage = message => {
  const createdAt = new Date(message.createdAt)
  return { ...message, createdAt }
}

export const get100LastDuels = async () => {
  const res = await http.get('/api/admin/duels/last-100')
  return res.map(duel => {
    const createdAt = new Date(duel.createdAt)
    const updatedAt = new Date(duel.updatedAt)
    const messages = duel.messages.map(toMessage)
    return { ...duel, createdAt, updatedAt, messages }
  })
}

export const addOpponent = async ({ did, uid }) => {
  const body = { did, uid }
  const res = await http.post('/api/admin/duels/add-opponent', body)
  return res
}

export const addMessageAs = async ({ did, uid, message }) => {
  const body = { did, uid, message }
  const res = await http.post('/api/admin/duels/add-message-as', body)
  return res
}

export const selectWinner = async ({ did, uid }) => {
  const body = { did, uid }
  const res = await http.post('/api/admin/duels/select-winner', body)
  return res
}

export const startConversation = async ({ did }) => {
  const body = { did }
  const res = await http.post('/api/admin/duels/start-conversation', body)
  return res
}

export const create = async ({ wooed, competitors }) => {
  const body = { wooed, competitors }
  const res = await http.post('/api/admin/duels/create', body)
  return res
}
