import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import styles from './configs.module.css'
import firebase from '../../../firebase'
import * as selectors from '../../../store/selectors'
import * as actions from '../../../store/actions/configs'
import Card from '../../../components/card'
import Loader from '../../../components/loader'
import { Input } from '../../../components/inputs'
import Button from '../../../components/button'
import Spacer from '../../../components/spacer'
import Image from '../../../components/image'

const LoaderCard = () => (
  <div className={styles.layout}>
    <Card center className={styles.loader}>
      <Loader />
    </Card>
  </div>
)

const Configs = () => {
  const dispatch = useDispatch()
  const configs = useSelector(selectors.configs)
  const [maxDuels, setMaxDuels] = useState(configs.maxDuels)
  const [skipDuels, setSkipDuels] = useState(configs.skipDuels)
  useEffect(() => {
    setMaxDuels(value => {
      const same = value !== configs.maxDuels
      return same ? configs.maxDuels : value
    })
    setSkipDuels(value => {
      const same = value !== configs.skipDuels
      return same ? configs.skipDuels : value
    })
  }, [configs])
  const onMaxClick = async () => {
    try {
      await dispatch(actions.updateMaxDuels(maxDuels))
      toast.success('Max Duels updated')
    } catch (error) {
      toast.error('Unable to update max duels. Try again later.')
    }
  }
  const onSkipClick = async () => {
    try {
      await dispatch(actions.updateSkipDuels(skipDuels))
      toast.success('Skip Duels updated')
    } catch (error) {
      toast.error('Unable to update skip duels. Try again later.')
    }
  }
  if (!maxDuels || !skipDuels) {
    return <LoaderCard />
  } else {
    return (
      <div className={styles.layout}>
        <Card
          area="max-duels"
          title="Update max duels"
          subtitle="Be careful, it will have an impact on everyone"
        >
          <Input
            type="number"
            label="Max Duels"
            placeholder="10"
            value={maxDuels}
            onChange={setMaxDuels}
          />
          <Spacer size={24} />
          <div className={styles.buttons}>
            <Button
              secondary
              disabled={maxDuels === configs.maxDuels}
              title="Cancel"
              onClick={() => setMaxDuels(configs.maxDuels)}
            />
            <Spacer size={24} />
            <Button
              primary
              disabled={maxDuels === configs.maxDuels}
              title="Submit"
              onClick={onMaxClick}
            />
          </div>
        </Card>
        <Card
          area="max-skip"
          title="Update max skip"
          subtitle="Be careful, it will have an impact on everyone"
        >
          <Input
            type="number"
            label="Max Skips"
            placeholder="10"
            value={skipDuels}
            onChange={setSkipDuels}
          />
          <Spacer size={24} />
          <div className={styles.buttons}>
            <Button
              secondary
              disabled={skipDuels === configs.skipDuels}
              title="Cancel"
              onClick={() => setSkipDuels(configs.skipDuels)}
            />
            <Spacer size={24} />
            <Button
              primary
              disabled={skipDuels === configs.skipDuels}
              title="Submit"
              onClick={onSkipClick}
            />
          </div>
        </Card>
      </div>
    )
  }
}

export default Configs
