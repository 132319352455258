import * as helpers from '../../helpers/likes'
import * as services from '../../services/likes'

export const UPDATE_LIKES = 'likes/UPDATE_LIKES'
export const POSTPONE_USER = 'POSTPONE_USER'
export const REMOVE_MESSAGES = 'REMOVE_MESSAGES'
export const DECREMENT_COUNT = 'DECREMENT_COUNT'

export const decrementCount = ({ id, competitors }) => {
  return { type: DECREMENT_COUNT, id, competitors }
}

export const get = () => async dispatch => {
  const docs = await services.all()
  const postponed = helpers.getUsers()
  const likes = docs.filter(doc => {
    const value = (postponed[doc.id] ?? Date.now()) <= Date.now()
    if (value) {
      delete postponed[doc.id]
      helpers.storeUsers(postponed)
    }
    return value
  })
  await dispatch({ type: UPDATE_LIKES, likes })
}

export const postpone = id => async dispatch => {
  helpers.ignoreUser(id)
  await dispatch({ type: POSTPONE_USER, id })
}

export const removeMessages = (id, messages) => async dispatch => {
  services.removeMessages(id, messages)
  dispatch({ type: REMOVE_MESSAGES, messages, id })
}
