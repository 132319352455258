import { UPDATE_SEARCH } from '../actions/miscellaneous'

const initialState = {
  search: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH: {
      const { search } = action
      return { ...state, search }
    }
    default:
      return state
  }
}

export default reducer
