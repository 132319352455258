import { useSelector } from 'react-redux'
import Table from '../../../components/table'
import * as selectors from '../../../store/selectors'
import { useBodySize } from '../../../helpers/body'
import { useWidth } from '../../../helpers/window'
import styles from './conversations.module.css'
import check from './check.png'
import cross from './cross.png'

const RenderStatus = ({ status }) => {
  const surroundCl = status ? styles.crossItem : styles.checkItem
  const icon = status ? cross : check
  const alt = status ? 'Cross' : 'Check'
  return (
    <div className={surroundCl}>
      <img src={icon} className={styles.img} alt={alt} />
    </div>
  )
}

const renderBlocked = ([uid, status]) => (
  <div className={styles.blocked} key={uid}>
    <code className={styles.blockedId}>#{uid}</code>
    <RenderStatus status={status} />
  </div>
)

const RenderLastMessage = ({ conversation }) => {
  const message = conversation.lastMessage
  const isWooed = message?.uid === conversation.wooed
  const background = isWooed ? 'var(--pink)' : 'var(--purple)'
  if (message) {
    return (
      <div className={styles.lastMessageWrapper}>
        <div className={styles.lastMessage} style={{ background }}>
          {message.content}
        </div>
        <div className={styles.lastMessageAuthor}>
          {isWooed ? 'Wooed' : 'Competitor'}
        </div>
      </div>
    )
  } else {
    return null
  }
}

const toTableData = conversation => {
  const blocked = Object.entries(conversation.blocked).map(renderBlocked)
  return {
    blocked,
    id: `#${conversation.id}`,
    uids: conversation.users.map(t => `#${t}`).join('\n'),
    to: `/conversations/${conversation.id}`,
    updatedAt: conversation.updatedAt,
    lastMessage: <RenderLastMessage conversation={conversation} />,
  }
}

const searchFilter = search => {
  const usersReducer = (acc, user) => acc || user.includes(search)
  return conversation => {
    if (search.length === 0) {
      return [toTableData(conversation)]
    } else {
      const result =
        conversation.users.reduce(usersReducer, false) ||
        conversation.id.includes(search)
      return result ? [toTableData(conversation)] : []
    }
  }
}

const fields = ['id', 'uids', 'blocked', 'updatedAt', 'lastMessage']

const Conversations = () => {
  const search = useSelector(selectors.search)
  const conversations = useSelector(selectors.conversations)
  const filtered = Object.values(conversations).flatMap(searchFilter(search))
  const bodySize = useBodySize(24)
  const width = useWidth()
  const style = width > 450 ? { maxHeight: bodySize } : {}
  return (
    <div className={styles.conversationsGrid} style={style}>
      <Table fields={fields} data={filtered} />
    </div>
  )
}

export default Conversations
