import { useRef } from 'react'
import * as Recharts from 'recharts'
import Card from '../../../components/card'
import strings from './analytics.strings.json'

const MyCustomNode = ({ container, x, y, width, height, index, payload }) => {
  const containerWidth = container?.current?.getBoundingClientRect?.()?.width
  const isOut = x + width + 6 > containerWidth
  return (
    <Recharts.Layer key={`CustomNode${index}`}>
      <Recharts.Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill="#5192ca"
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize="14"
        stroke="#333"
      >
        {payload.name}
      </text>
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 15}
        fontSize="12"
        stroke="#333"
        strokeOpacity="0.5"
      >
        {payload.value}
      </text>
    </Recharts.Layer>
  )
}

export const Chart = ({ data }) => {
  const ref = useRef()
  return (
    <Card
      title={strings.onBoarding.title}
      subtitle={strings.onBoarding.subtitle}
      area="on-boarding"
    >
      <Recharts.ResponsiveContainer width="100%" height={400} ref={ref}>
        <Recharts.Sankey
          node={props => <MyCustomNode container={ref.current} {...props} />}
          margin={{ top: 24, left: 0, right: 0, bottom: 0 }}
          data={data}
          iterations={128}
          link={{ stroke: '#77c878' }}
        >
          <Recharts.Tooltip />
        </Recharts.Sankey>
      </Recharts.ResponsiveContainer>
    </Card>
  )
}

export const formatData = onBoarding => ({
  nodes: [
    { name: 'Total' },
    { name: 'Finished' },
    { name: 'Demo' },
    { name: 'Avatar' },
    { name: 'Dropped' },
  ],
  links: [
    { source: 0, target: 1, value: onBoarding.finished },
    { source: 0, target: 4, value: onBoarding.total - onBoarding.finished },
    { source: 1, target: 2, value: onBoarding.demo },
    { source: 1, target: 4, value: onBoarding.finished - onBoarding.demo },
    { source: 2, target: 3, value: onBoarding.avatar },
    { source: 2, target: 4, value: onBoarding.demo - onBoarding.avatar },
  ],
})
