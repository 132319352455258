import { useSelector } from 'react-redux'
import Duel from '../../../components/duel'
import Button from '../../../components/button'
import * as selectors from '../../../store/selectors'
import styles from './duels.module.css'
import strings from './duels.strings.json'

const indexToTitle = index => {
  if (index === 0) {
    return 'Looking For Challenger'
  } else if (index === 1) {
    return 'Started'
  } else if (index === 2) {
    return 'Deliberation In Progress'
  } else {
    return 'Ended'
  }
}

const searchFilter = search => {
  const lower = search.toLowerCase()
  const compsReduce = (acc, competitor) => acc || competitor.includes(search)
  const messagesReduce = (acc, message) => {
    const content = message.content.toLowerCase()
    return acc || content.includes(lower)
  }
  return duel =>
    search.length === 0 ||
    duel.id.includes(search) ||
    duel.messages.reduce(messagesReduce, false) ||
    duel.competitors.reduce(compsReduce, false) ||
    duel.wooed.includes(search) ||
    duel.question.includes(lower) ||
    duel.winner?.includes(search)
}

const Duels = () => {
  const search = useSelector(selectors.search)
  const state = useSelector(selectors.duels)
  const { duels, choosables } = state
  const filterer = searchFilter(search)
  const deliberation = duels.filter(d => d.state === 'deliberation-in-progress')
  const ended = duels.filter(d => d.state === 'ended')
  return (
    <div>
      <div className={styles.leftButton}>
        <Button title={strings.buttons.newDuel} primary nav to="/new-duel" />
      </div>
      {[deliberation, ended].map((duels, index) => {
        const title = indexToTitle(index)
        const filtered = duels.filter(filterer)
        return (
          filtered.length > 0 && (
            <div key={title} className={styles.duelWrapper}>
              <div className={styles.title}>{title}</div>
              <div className={styles.layout}>
                {filtered.map(duel => (
                  <Duel key={duel.id} choosables={choosables} duel={duel} />
                ))}
              </div>
            </div>
          )
        )
      })}
    </div>
  )
}

export default Duels
