import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import firebase from '../firebase'
import * as actions from '../store/actions/conversations'
import * as selectors from '../store/selectors'

const conversations = () => firebase.firestore().collection('conversations')
const messages = cid => conversations().doc(cid).collection('messages')

export const useConversations = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    return conversations()
      .orderBy('updatedAt', 'desc')
      .onSnapshot(({ docs }) => {
        const convs = docs.reduce((acc, doc) => {
          const data = doc.data({ serverTimestamps: 'estimate' })
          const createdAt = data.createdAt.toDate()
          const updatedAt = data.updatedAt.toDate()
          const id = doc.id
          const conversation = { ...data, createdAt, updatedAt, id }
          return { ...acc, [id]: conversation }
        }, {})
        dispatch(actions.updateConversations(convs))
      })
  }, [dispatch])
}

export const useConversation = () => {
  const { cid } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    return messages(cid)
      .orderBy('createdAt', 'asc')
      .onSnapshot(({ docs }) => {
        const messages = docs.map(doc => {
          const data = doc.data({ serverTimestamps: 'estimate' })
          const createdAt = data.createdAt.toDate()
          return { ...data, createdAt, id: doc.id }
        })
        dispatch(actions.updateMessages(cid, messages))
      })
  }, [dispatch, cid])
  return useSelector(selectors.conversation(cid))
}
