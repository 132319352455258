export default [
  'Magret de canard',
  'Moules-frites',
  'Couscous',
  'Blanquette de veau',
  'Côte de bœuf',
  "Gigot d'agneau",
  'Steak-frites',
  'Bœuf bourguignon',
  'Raclette',
  'Tomates farcies',
  'Fondant au chocolat',
  'Crêpes',
  'Mousse au chocolat',
  'Île flottante',
  'Tarte aux pommes',
  'Tiramisu',
  'Crème brûlée',
  'Profiteroles',
  'Millefeuille',
  'Tarte aux fraises',
  'Gratin Dauphinois',
  'Quiche Lorraine',
  'Poulet Basquaise',
  'Chili con carne',
  'Ratatouille',
  'Pizza quatre fromages',
  'Pates aux pesto',
  'Burger - frites',
  'Poulet curry',
  'Bobun',
  'Sauté de veau',
  'Tartiflette',
  'Tajine de poulet',
  'Pavé de saumon',
  'Lasagnes à la bolognaise',
  'Poulet au curry',
  'Paupiettes de veau',
  'Risotto ',
  'Blanquette de poisson',
  'Aubergines farcies',
  'Chich Taouk',
  'Fondue savoyarde',
  'Paella',
  'Sushis',
  'Poke bowl saumon',
  'Sole au four',
  'Tomates farcies',
  'Fruits de mer',
  'Salade grecque',
  'Brochettes d’agneau',
  'Tarte chèvre',
  'Tomates-cerises',
  'Quiche aux légumes',
  'Bibimbap',
  'Barbecue coréen',
  'Calzone au speck',
  'Spaghettis bolognaise',
  'Pâtes au saumon',
  'Velouté de petit pois',
  'Frites maison',
  'Galette saucisse',
  'Salade japonaise',
  'Pancakes au chocolat',
  'Pad thaï au tofu',
  'Dim Sum ',
  'Ramens',
  'Canard Laqué',
  'Glace à la vanille',
  'Curry massaman',
  'Burrito',
  'Cheeseburger',
  'Pizza Margherita',
  'Sandwich falafel',
  'Kebab berlinois',
  'Raviolis ',
  'Dinde de Thanksgiving',
  'Lasagne italienne',
  'Pizza napolitaine',
  'Gnocchis alla romana',
  'Osso-bucco à la milanaise',
  'Poulet massa',
  'Thieboudienne poulet',
  'Tartare',
  'Oeuf mollet',
  'Omelette champignons',
  'Carpaccio',
  'Tempura',
  'Gyoza',
  'Nems aux légumes',
  'Soupe Miso',
  'Udon',
  'Tonkatsu',
  'Entrecôte milanaise',
  'Welsh',
  'Andouillette',
  'Fish and Chips',
  'Tortilla',
  'Gaspacho',
  'Churros',
  'Empanadas',
]
