import styles from './nested-list.module.css'

const selectTranslationLang = translations => {
  return translations?.fr ?? translations?.en
}

const selectTranslation = value => {
  return (
    selectTranslationLang(value?.translations) ??
    selectTranslationLang(value?.translations?.singular) ??
    selectTranslationLang(value?.translations?.plural) ??
    value?.id
  )
}

const SelectableQuestion = ({ selected, setSelected, value, disabled }) => {
  const selectedId = selected === value.id
  const selectedBlockName = selectedId
    ? styles.selectedQuestion
    : styles.mainQuestion
  const selectedClassName = selectedId ? styles.redBlock : styles.block
  const onClick = () => !disabled && setSelected(value?.id)
  return (
    <div
      className={selectedBlockName}
      style={{ cursor: disabled && 'not-allowed' }}
      onClick={onClick}
    >
      <div className={selectedClassName} />
      <div className={styles.questionName}>{selectTranslation(value)}</div>
    </div>
  )
}

const NestedList = ({ values, ...props }) => (
  <div className={styles.selector}>
    {Object.entries(values).map(([key, value]) => {
      const selectedSection = value.find(({ id }) => props.selected === id)
      const selectedSectionName = selectedSection
        ? styles.selectedTitle
        : styles.paddedTitle
      const className = selectedSection ? styles.redBlock : styles.block
      const onClick = () => !props.disabled && props.setSelected(value?.[0]?.id)
      return (
        <div key={key} className={styles.categoryName}>
          <div
            className={selectedSectionName}
            onClick={onClick}
            style={{ cursor: props.disabled && 'not-allowed' }}
          >
            <div className={className} />
            {key}
          </div>
          <div className={styles.questionsSection}>
            {value.map(val => (
              <SelectableQuestion key={val?.id} value={val} {...props} />
            ))}
          </div>
        </div>
      )
    })}
  </div>
)

export default NestedList
