import * as Recharts from 'recharts'
import Card from '../../../components/card'
import strings from './analytics.strings.json'

const margins = { top: 24, left: 0, right: 0, bottom: 0 }
const colors = ['#8884d8', '#82ca9d', '#c98282']

const RenderCell = (_, index) => {
  const fill = colors[index]
  const key = `cell-${index}`
  return <Recharts.Cell key={key} fill={fill} />
}

const common = {
  dataKey: 'value',
  nameKey: 'name',
  cx: '50%',
  cy: '50%',
  legendType: 'circle',
}

const removeTotal = data => data.filter(d => d.name !== 'Total')
const keepTotal = data => data.filter(d => d.name === 'Total')

export const Chart = ({ data }) => (
  <Card
    title={strings.archives.title}
    subtitle={strings.archives.subtitle}
    area="archived"
  >
    <Recharts.ResponsiveContainer width="100%" height={400}>
      <Recharts.PieChart data={data} margin={margins}>
        <Recharts.Pie
          {...common}
          data={removeTotal(data)}
          outerRadius={120}
          innerRadius={60}
          label
        >
          {data.map(RenderCell)}
        </Recharts.Pie>
        <Recharts.Pie {...common} data={keepTotal(data)} outerRadius={60} />
        <Recharts.Tooltip />
        <Recharts.Legend />
      </Recharts.PieChart>
    </Recharts.ResponsiveContainer>
  </Card>
)

const replaceKey = key => {
  return key
    .replace('accepted', 'Accepted')
    .replace('rejected', 'Rejected')
    .replace('flagged', 'Flagged')
    .replace('total', 'Total')
}

export const formatData = archived => {
  return Object.entries(archived).map(([key, value]) => {
    return { value, name: replaceKey(key) }
  })
}
