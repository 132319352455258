import * as duelsService from '../../services/duels'
import * as profilesActions from './profiles'

export const UPDATE_DUELS = 'UPDATE_DUELS'
export const UPDATE_DUEL = 'UPDATE_DUEL'
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION'
export const UPDATE_SINGLE_MESSAGE = 'UPDATE_SINGLE_MESSAGE'
export const UPDATE_WINNER_DUEL = 'UPDATE_WINNER_DUEL'

export {
  STARTED,
  DELIBERATION_IN_PROGRESS,
  LOOKING_FOR_CHALLENGER,
  ENDED,
} from '../../services/duels'

const updateDuels = duels => {
  return { type: UPDATE_DUELS, duels }
}

export const get100LastDuels = () => async dispatch => {
  const duels = await duelsService.get100LastDuels()
  await dispatch(updateDuels(duels))
  return duels
}

export const addOpponent =
  ({ uid, did }) =>
  async (dispatch, getState) => {
    const valid = await duelsService.addOpponent({ uid, did })
    if (valid) {
      const state = getState()
      if (!state[uid]) await dispatch(profilesActions.get(uid))
      await dispatch({ type: UPDATE_DUEL, did, uid })
      return true
    } else {
      return false
    }
  }

export const addMessageAs =
  ({ did, uid, message }) =>
  async dispatch => {
    const valid = await duelsService.addMessageAs({ did, uid, message })
    if (valid) {
      await dispatch({ type: UPDATE_SINGLE_MESSAGE, did, uid, message })
      return true
    } else {
      return false
    }
  }

export const selectWinner =
  ({ did, uid }) =>
  async dispatch => {
    const valid = await duelsService.selectWinner({ did, uid })
    if (valid) {
      await dispatch({ type: UPDATE_WINNER_DUEL, did, uid })
      return true
    } else {
      return false
    }
  }

export const startConversation = ({ did }) => {
  return async dispatch => {
    await duelsService.startConversation({ did })
    await dispatch({ type: UPDATE_CONVERSATION, did })
  }
}
