import * as Recharts from 'recharts'
import Card from '../../../components/card'
import strings from './analytics.strings.json'

const margins = { top: 24, left: 0, right: 0, bottom: 0 }

export const Chart = ({ data }) => (
  <Card
    title={strings.duels.title}
    subtitle={strings.duels.subtitle}
    area="duels"
  >
    <Recharts.ResponsiveContainer width="100%" height={400}>
      <Recharts.BarChart data={data} margin={margins} barCategoryGap="5%">
        <Recharts.Tooltip />
        <Recharts.CartesianGrid strokeDasharray="3 3" />
        <Recharts.Bar stackId="a" dataKey="Deliberation" fill="#c98282" />
        <Recharts.Bar stackId="a" dataKey="Ended" fill="#82ca9d" />
        <Recharts.XAxis dataKey="name" />
        <Recharts.YAxis type="number" mirror />
      </Recharts.BarChart>
    </Recharts.ResponsiveContainer>
  </Card>
)

const replaceKey = key => {
  return key
    .replace('total', 'Total')
    .replace('deliberation', 'Deliberation')
    .replace('ended', 'Ended')
}

export const formatData = duels => {
  const sorted = Object.entries(duels).sort(([a], [b]) => b.localeCompare(a))
  const last30 = sorted.slice(0, 30).reverse()
  return last30.map(([key, values]) => {
    const name = key.split('-').reverse().join('/')
    const capitalize = ([key, value]) => [replaceKey(key), value]
    const vals = Object.fromEntries(Object.entries(values).map(capitalize))
    return { name, ...vals }
  })
}
