import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../../components/loader'
import { Input } from '../../../components/inputs'
import Card from '../../../components/card'
import Button from '../../../components/button'
import * as selectors from '../../../store/selectors'
import * as actions from '../../../store/actions/settings'
import styles from './moderators.module.css'
import strings from './moderators.strings.json'

const Moderator = ({ user, claim, name, id, uid, email, onClick, picture }) => {
  const isAdmin = claim === 'admin'
  const claimStyle = isAdmin ? styles.adminClaim : styles.modoClaim
  return (
    <Card className={styles.padded}>
      <div className={styles.adminPill}>
        <img src={picture} className={styles.profilePicture} alt="Profile" />
        <div style={{ overflow: 'hidden', gridArea: 'info' }}>
          <div className={styles.nameUid}>
            <div className={styles.nameStatus}>
              <div className={styles.name}>{name ?? 'No name'}</div>
              <div className={claimStyle}>{claim}</div>
            </div>
            <code className={styles.uid}>#{uid}</code>
          </div>
          <a href={`mailto:${email}`} className={styles.email}>
            {email}
          </a>
        </div>
        {uid !== user.uid && (
          <div className={styles.centerButton}>
            <Button
              onClick={() => onClick(id)}
              primary
              title={strings.button.delete}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

const Admins = () => {
  const moderators = useSelector(selectors.moderators)
  const [loading, setLoading] = useState(moderators.length === 0)
  const [email, setEmail] = useState('')
  const [claim, setClaim] = useState('moderator')
  const user = useSelector(state => state.settings.user)
  const dispatch = useDispatch()
  useEffect(() => {
    if (moderators.length === 0) {
      dispatch(actions.updateModerators()).then(() => setLoading(false))
    }
  }, [moderators, dispatch])
  const onClick = async id => {
    setLoading(true)
    try {
      await dispatch(actions.removeModerator(id))
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const disabled = email.length === 0
  const onSubmit = async event => {
    event.preventDefault()
    if (disabled) return
    setLoading(true)
    try {
      await dispatch(actions.addModerator({ email: email.trim(), claim }))
      setEmail('')
      setClaim('moderator')
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className={styles.layout}>
      <Card
        className={styles.paddedBottom}
        title={strings.input.title}
        subtitle={strings.input.subtitle}
      >
        <form onSubmit={onSubmit} className={styles.mainForm}>
          <Input
            label={strings.input.email}
            placeholder="example@example.com"
            onChange={setEmail}
            value={email}
          />
          <div className={styles.buttonsForm}>
            <div className={styles.optionsButtons}>
              <Button
                className={styles.margedLeft}
                secondary={claim === 'admin'}
                shutdown
                title={strings.button.admin}
                onClick={() => setClaim('admin')}
              />
              <Button
                className={styles.margedLeft}
                title={strings.button.moderator}
                secondary={claim === 'moderator'}
                shutdown
                onClick={() => setClaim('moderator')}
              />
            </div>
            <Button
              className={styles.margedLeft}
              submit
              primary
              disabled={disabled}
              title={strings.button.submit}
            />
          </div>
        </form>
      </Card>
      {loading ? (
        <Card center className={styles.padded}>
          <Loader />
        </Card>
      ) : (
        moderators.map(moderator => (
          <Moderator
            key={moderator.id}
            onClick={onClick}
            user={user}
            {...moderator}
          />
        ))
      )}
    </div>
  )
}

export default Admins
