const USERS_KEY = 'user-key-courtisan'

export const getUsers = () => {
  const fromStorage = localStorage.getItem(USERS_KEY) ?? '{}'
  return JSON.parse(fromStorage)
}

export const storeUsers = users => {
  localStorage.setItem(USERS_KEY, JSON.stringify(users))
}

export const ignoreUser = id => {
  const date = new Date()
  const hours = date.getHours()
  const delta = 32 - hours
  date.setHours(hours + delta)
  const data = date.getTime()
  const users = getUsers()
  storeUsers({ ...users, [id]: data })
}
