import * as duels from './duels'
import * as choosables from './choosables'
import * as configs from './configs'
import * as moderatorsService from '../../services/moderators'
import * as profilesService from '../../services/profiles'

export const UPDATE_USER = 'settings/UPDATE_USER'
export const UPDATE_TOKEN = 'settings/UPDATE_TOKEN'
export const UPDATE_MODERATORS = 'settings/UPDATE_MODERATORS'
export const UPDATE_USERS = 'settings/UPDATE_USERS'
export const DISABLE_USER = 'settings/DISABLE_USER'

const dispatchUpdateToken = token => {
  return { type: UPDATE_TOKEN, token }
}

const dispatchUpdateModerators = moderators => {
  return { type: UPDATE_MODERATORS, moderators }
}

const dispatchUpdateUsers = ({ users, page, disabled }) => {
  return { type: UPDATE_USERS, users, page, disabled }
}

export const updateUser = user => async dispatch => {
  await dispatch({ type: UPDATE_USER, user })
  if (user) {
    const token = await user.getIdToken()
    await dispatch(dispatchUpdateToken(token))
    const choo = dispatch(choosables.get())
    const conf = dispatch(configs.get())
    const avat = dispatch(choosables.avatars())
    const duel = dispatch(duels.get100LastDuels())
    await Promise.all([choo, avat, duel, conf])
  }
}

export const updateToken = () => async (dispatch, getState) => {
  const state = getState()
  const { user } = state.settings
  const token = await user.getIdToken()
  await dispatch(dispatchUpdateToken(token))
}

export const updateModerators = () => async dispatch => {
  const moderators = await moderatorsService.all()
  await dispatch(dispatchUpdateModerators(moderators))
}

export const addModerator = ({ email, claim }) => {
  return async dispatch => {
    await moderatorsService.add({ email, claim })
    await dispatch(updateModerators())
  }
}

export const removeModerator = id => async dispatch => {
  await moderatorsService.remove(id)
  await dispatch(updateModerators())
}

export const listUsers = async (dispatch, getState) => {
  const state = getState()
  if (state.settings.user !== 'loading') {
    const token = state.settings.page
    const users = await profilesService.list(token)
    if (users) {
      const disabled = users.length === 0
      await dispatch(dispatchUpdateUsers({ disabled, users, page: token + 1 }))
    }
  }
}

export const fakeUsers = () => async _dispatch => {
  for (let i = 0; i < 10; i++) {
    await profilesService.fake()
  }
}

export const disableUser = uid => async dispatch => {
  const result = await profilesService.disable(uid)
  if (result) dispatch({ type: DISABLE_USER, uid })
  return result
}
