import { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as selectors from '../../../store/selectors'
import * as profilesActions from '../../../store/actions/profiles'
import * as duelsActions from '../../../store/actions/duels'
import * as settingsActions from '../../../store/actions/settings'
import DuelView from '../../../components/duel'
import Loader from '../../../components/loader'
import Card from '../../../components/card'
import Image from '../../../components/image'
import Button from '../../../components/button'
import Spacer from '../../../components/spacer'
import * as Inputs from '../../../components/inputs'
import styles from './duel.module.css'
import strings from './duel.strings.json' // eslint-disable-line

const toLocaleBirthday = birthday => {
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }
  return birthday.toLocaleString('fr-fr', options)
}

export const Profile = ({
  duel,
  first,
  area,
  profile,
  choosables,
  token,
  row,
}) => {
  const dispatch = useDispatch()
  const loading = !profile
  if (loading || !profile) {
    return (
      <Card area={area} center>
        {loading && profile && <Loader className={styles.loaderProfile} />}
      </Card>
    )
  } else {
    const { qualities, questions, sexes } = choosables
    // prettier-ignore
    const findById = id_ => ({ id }) => id === id_
    const quality = qualities.find(findById(profile.quality))
    const searchedQuality = qualities.find(findById(profile.searchedQuality))
    const selectedQuestion = questions.find(findById(profile.selectedQuestion))
    const searchedGender = profile.searchedGender.flatMap(gender => {
      const g = sexes.find(findById(gender))
      return g ? [g] : []
    })
    const sex = sexes.find(findById(profile.sex))
    const pic = profile.pics[0]
    const onImageError = () => dispatch(settingsActions.updateToken())
    const pictureClassName = first
      ? styles.profileWrapperPurple
      : styles.profileWrapperPink
    const high =
      profile.highAge === 80 ? profile.highAge + '+' : profile.highAge
    return (
      <Card
        area={area}
        title={profile.firstName}
        subtitle={`#${profile.id}`}
        icon={
          <Image
            Loader={<Loader />}
            url={`/pictures/${profile.id}/${pic}`}
            headers={{ Authorization: `Bearer ${token}` }}
            className={pictureClassName}
            imgClassName={styles.profilePicture}
            onError={onImageError}
            alt=""
          />
        }
      >
        {duel?.winner === profile?.id && (
          <div className={styles.winnerPillWrapper}>
            <div className={styles.winnerPill}>Winner</div>
          </div>
        )}
        <div className={row ? styles.profileRow : styles.profileGrid}>
          <div className={styles.profileItem}>
            🙂 {quality?.translations?.fr}
          </div>
          <div className={styles.profileItem}>
            😍 {searchedQuality?.translations?.fr}
          </div>
          <div className={styles.profileItem}>🍿 {profile.favoriteMovie}</div>
          <div className={styles.profileItem}>🥢 {profile.favoriteMeal}</div>
          <div className={styles.profileItem}>
            🎂 {toLocaleBirthday(profile.birthday)}
          </div>
          <div className={styles.profileItem}>
            🍆 {sex?.translations?.plural?.fr}
          </div>
          <div className={styles.profileItem}>
            🔎{' '}
            {searchedGender
              .map(gender => gender.translations?.plural?.fr)
              .join(' & ')}
          </div>
          <div className={styles.profileItem}>
            😘 {[profile.lowAge, high].join(' - ')}
          </div>
          <div className={styles.profileItem}>
            ﹖ {selectedQuestion?.translations?.fr}
          </div>
        </div>
      </Card>
    )
  }
}

const LoaderWrapper = ({ loading, children }) => {
  if (loading) {
    return <Loader />
  } else {
    return children ?? null
  }
}

const LookingForChallengerAction = props => {
  const dispatch = useDispatch()
  const [uid, setUID] = useState('')
  const [loading, setLoading] = useState(false)
  const buttonEnabled = uid.length >= 20 || uid.endsWith('@courtisan.fake')
  const onClick = async () => {
    if (buttonEnabled) {
      setLoading(true)
      const did = props.duel.id
      await dispatch(duelsActions.addOpponent({ uid, did }))
    }
  }
  return (
    <Card
      area="action"
      title="Looking For Challenger"
      subtitle="Add an opponent"
      center={loading}
    >
      <LoaderWrapper loading={loading}>
        <Spacer size={18} />
        <Inputs.Input
          label="Enter an email or a UID"
          value={uid}
          onChange={setUID}
          placeholder="0Lvot6LN74N0jM15diFpj9NSyuc2"
          onCmdEnter={onClick}
        />
        <Spacer size={18} />
        <Button
          primary
          disabled={!buttonEnabled}
          title="Valider"
          onClick={onClick}
        />
      </LoaderWrapper>
    </Card>
  )
}

const userButton =
  ({ profiles, setAsUser, asUser }) =>
  (uid, index) => {
    const prof = profiles.find(p => p?.id === uid)
    const onChange = () => setAsUser(asUser === prof?.id ? null : prof?.id)
    return (
      <Button
        key={uid}
        className={styles.competitorsButton}
        shutdown={prof?.id !== asUser}
        primary={prof?.id === asUser}
        onClick={onChange}
        title={prof?.firstName}
      />
    )
  }

const StartedAction = ({ duel, profiles, ...props }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [asUser, setAsUser] = useState()
  const [message, setMessage] = useState('')
  const onClick = async () => {
    if (asUser && message.length > 0) {
      setLoading(true)
      const did = duel.id
      const uid = asUser
      await dispatch(duelsActions.addMessageAs({ did, uid, message }))
      if (duel.messages.length + 1 < duel.competitors.length) {
        setMessage('')
        setLoading(false)
      }
    }
  }
  const comps = duel.competitors.filter(competitor => {
    return !duel.messages.find(m => m.uid === competitor)
  })
  return (
    <Card
      area="action"
      title="Started"
      subtitle="Post a message as someone"
      center={loading}
    >
      <LoaderWrapper loading={loading}>
        <Spacer size={18} />
        <div className={styles.competitors}>
          {comps.map(userButton({ profiles, asUser, setAsUser }))}
        </div>
        <Inputs.Input
          multiline
          value={message}
          onChange={setMessage}
          label="New message"
          placeholder="Tu es l'étoile de ma vie…"
          onCmdEnter={onClick}
        />
        <Spacer size={18} />
        <div className={styles.left}>
          <Button
            primary
            disabled={!asUser || message.length === 0}
            title="Valider"
            onClick={onClick}
          />
        </div>
      </LoaderWrapper>
    </Card>
  )
}

const DeliberationInProgressAction = props => {
  const { duel, profiles } = props
  const dispatch = useDispatch()
  const [asUser, setAsUser] = useState()
  const [loading, setLoading] = useState(false)
  const onClick = async () => {
    try {
      setLoading(true)
      const did = duel.id
      const uid = asUser
      await dispatch(duelsActions.selectWinner({ did, uid }))
    } catch (error) {
      setLoading(false)
    }
  }
  return (
    <Card
      area="action"
      title="Deliberation In Progress"
      subtitle="Choose a winner"
      center={loading}
    >
      <LoaderWrapper loading={loading}>
        <Spacer size={18} />
        <div className={styles.competitors}>
          {duel.competitors.map(userButton({ profiles, asUser, setAsUser }))}
        </div>
        <Spacer size={18} />
        <div className={styles.left}>
          <Button
            primary
            disabled={!asUser}
            title="Valider"
            onClick={onClick}
          />
        </div>
      </LoaderWrapper>
    </Card>
  )
}

const EndedAction = ({ duel }) => {
  const [loading, setLoading] = useState(false)
  const buttonTitle = duel.conversation
    ? strings.buttons.conversation.goto
    : strings.buttons.conversation.start
  const to = duel.conversation ? `/conversations/${duel.id}` : null
  const dispatch = useDispatch()
  const onClick = async () => {
    if (!duel.conversation && !loading) {
      setLoading(true)
      const did = duel.id
      await dispatch(duelsActions.startConversation({ did }))
      setLoading(false)
    }
  }
  return (
    <Card area="action" title="Ended" subtitle="Nothing to do more!">
      <div className={styles.winnerPillWrapper}>
        <div className={styles.archivePill}>
          {duel.archived ? 'Archived' : 'Not Archived'}
        </div>
      </div>
      <Spacer size={24} />
      <div className={styles.title}>Conversation</div>
      <Spacer size={24} />
      {!loading && (
        <div className={styles.right}>
          <Button
            primary
            nav={duel.conversation}
            to={to}
            title={buttonTitle}
            onClick={onClick}
            disabled={loading}
          />
        </div>
      )}
      {loading && (
        <div className={styles.loaderCenter}>
          <Loader />
        </div>
      )}
    </Card>
  )
}

const Action = ({ duel, ...props }) => {
  switch (duel?.state) {
    case duelsActions.LOOKING_FOR_CHALLENGER:
      return <LookingForChallengerAction duel={duel} {...props} />
    case duelsActions.STARTED:
      return <StartedAction duel={duel} {...props} />
    case duelsActions.DELIBERATION_IN_PROGRESS:
      return <DeliberationInProgressAction duel={duel} {...props} />
    case duelsActions.ENDED:
      return <EndedAction duel={duel} {...props} />
    default:
      return (
        <Card area="action" center>
          <Loader />
        </Card>
      )
  }
}

const useFetchProfiles = duel => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (duel) {
      const actions = [...duel.competitors, duel.wooed].map(uid => {
        const action = profilesActions.get(uid)
        return dispatch(action)
      })
      Promise.all(actions)
    }
  }, [dispatch, duel])
}

const Duel = ({ match }) => {
  const { id } = match.params
  const { duel, profiles, choosables, token } = useSelector(selectors.duel(id))
  const messages = duel?.messages || []
  const competitors = duel?.competitors || []
  const uids = messages.map(({ uid }) => uid)
  const otherUids = competitors.filter(id => !uids.includes(id))
  const byUid = uid => profiles[uid]
  const [firstProfile, secondProfile] = [...uids, ...otherUids].map(byUid)
  const wooedProfile = profiles[duel?.wooed]
  useFetchProfiles(duel)
  return (
    <div className={styles.layout}>
      <Fragment>
        <Profile
          profile={wooedProfile}
          choosables={choosables}
          token={token}
          area="courted"
          row
        />
        {duel ? (
          <DuelView
            duel={duel}
            choosables={choosables}
            className={styles.duelCard}
            seeMore={false}
          />
        ) : (
          <Card area="duel" center>
            <Loader className={styles.loaderProfile} />
          </Card>
        )}
        <Profile
          duel={duel}
          token={token}
          choosables={choosables}
          area="first-profile"
          profile={firstProfile}
          first
        />
        <Profile
          duel={duel}
          token={token}
          choosables={choosables}
          area="second-profile"
          profile={secondProfile}
        />
        <Action duel={duel} profiles={[firstProfile, secondProfile]} />
      </Fragment>
    </div>
  )
}

export default Duel
