import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import styles from './conversation.module.css'
import firebase from '../../../firebase'
import * as selectors from '../../../store/selectors'
import { useColorTheme } from '../../../helpers/appearance'
import { useConversation } from '../../../helpers/conversations'
import Card from '../../../components/card'
import Loader from '../../../components/loader'
import { Input } from '../../../components/inputs'
import Button from '../../../components/button'
import Spacer from '../../../components/spacer'
import Image from '../../../components/image'
import { useBodySize } from '../../../helpers/body'
import * as services from '../../../services/conversations'
// import eyeBlack from './eye-black.svg'
// import eyeWhite from './eye-white.svg'
import trashBlack from './trash-black.svg'
import trashWhite from './trash-white.svg'
import trashRed from './trash-red.svg'

const DisplayNames = ({ fst, snd, profiles, colors, token, id }) => (
  <div className={styles.nameWrapper}>
    <div className={styles.idTitle}>#{id}</div>
    <div className={styles.namesWrapper}>
      {[fst, snd].map(id => {
        const profile = profiles[id]
        const name = profile.firstName
        const color = colors[id]
        const background = `var(--${color})`
        return (
          <div className={styles.namePill} style={{ background }} key={id}>
            <Image
              headers={{ Authorization: `Bearer ${token}` }}
              url={`/pictures/${id}/${profile.pics[0]}`}
              className={styles.picture}
              imgClassName={styles.pictureImg}
            />
            {name}
          </div>
        )
      })}
    </div>
  </div>
)

const DisplayMessages = ({ cid, messages, colors }) => {
  const colorTheme = useColorTheme()
  const scrollRef = useRef()
  useEffect(() => {
    if (messages && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [messages])
  // const eye = colorTheme === 'dark' ? eyeWhite : eyeBlack
  const trash = colorTheme === 'dark' ? trashWhite : trashBlack
  return (
    <div className={styles.messagesWrapper} ref={scrollRef}>
      <div className={styles.topPadding} />
      {messages.map(message => {
        const color = colors[message.uid]
        const background = `var(--${color})`
        const clName = styles.coloredMessage
        const align = color === 'purple' ? styles.left : styles.right
        const deleteMessage = async () => {
          services.deleteMessage({ cid, mid: message.id })
        }
        const icons = !message.deleted && (
          <div className={styles.actionIcons}>
            <button
              className={styles.transparentButton}
              onClick={deleteMessage}
            >
              <img className={styles.trashImage} src={trash} alt="Delete" />
            </button>
          </div>
        )
        return (
          <div className={`${styles.messageWrapper} ${align}`} key={message.id}>
            {color === 'purple' && icons}
            <div className={clName} style={{ background }}>
              {message.content}
              {message.deleted && (
                <div className={styles.placeholderImages}>
                  <img
                    className={styles.deleteImage}
                    src={trashRed}
                    alt="Deleted"
                  />
                </div>
              )}
            </div>
            {color !== 'purple' && icons}
          </div>
        )
      })}
      <Spacer size={36} />
    </div>
  )
}

const InputAs = ({ fst, snd, profiles, messages, conversation }) => {
  const [content, setContent] = useState('')
  const [selected, setSelected] = useState()
  const onSubmit = async () => {
    const notBlocked = !conversation.blocked[selected]
    if (selected && notBlocked && content.length > 0) {
      setContent('')
      const createdAt = firebase.firestore.FieldValue.serverTimestamp()
      await firebase
        .firestore()
        .collection('conversations')
        .doc(conversation.id)
        .collection('messages')
        .add({ content, uid: selected, createdAt })
    }
  }
  return (
    <div className={styles.textInput}>
      <Spacer size={12} />
      <div className={styles.buttonName}>
        {!conversation.blocked[fst] && (
          <Button
            title={profiles[fst].firstName}
            shutdown={fst !== selected}
            onClick={() => setSelected(fst)}
            secondary={fst === selected}
          />
        )}
        {!conversation.blocked[snd] && (
          <Button
            title={profiles[snd].firstName}
            shutdown={snd !== selected}
            onClick={() => setSelected(snd)}
            primary={snd === selected}
          />
        )}
      </div>
      <Input
        placeholder="Aa"
        multiline
        rows={10}
        labelClassName={styles.labelInput}
        onChange={setContent}
        value={content}
        onCmdEnter={() => onSubmit()}
      />
      <div className={styles.smallHidden}>
        <Spacer size={12} />
      </div>
      <div className={styles.smallHidden}>
        Pro tip: You can also use Cmd + Enter to send the message only using the
        keyboard!
      </div>
      <Spacer size={24} />
      <Button
        submit
        title="Envoyer"
        disabled={
          content.length === 0 || !selected || conversation.blocked[selected]
        }
        primary
        onClick={onSubmit}
      />
    </div>
  )
}

const LoaderCard = () => (
  <div className={styles.layout}>
    <Card center className={styles.loader}>
      <Loader />
    </Card>
  </div>
)

const Conversation = () => {
  const { conversation, messages, profiles, token } = useConversation()
  const bodySize = useBodySize(24)
  if (!conversation || !messages) {
    return <LoaderCard />
  } else {
    const [fst, snd] = conversation.users
    const first = [fst, 'purple']
    const second = [snd, 'pink']
    const colors = Object.fromEntries([first, second])
    const cid = conversation.id
    return (
      <div className={styles.layout} style={{ height: bodySize }}>
        <Card area="body" className={styles.mainCard}>
          <DisplayNames
            id={cid}
            fst={fst}
            snd={snd}
            profiles={profiles}
            colors={colors}
            token={token}
          />
          <div className={styles.bodyCard}>
            <DisplayMessages messages={messages} colors={colors} cid={cid} />
          </div>
        </Card>
        <Card
          area="input"
          title="Write as"
          subtitle="Select someone, type some text, and just send it!"
        >
          <InputAs
            fst={fst}
            snd={snd}
            messages={messages}
            conversation={conversation}
            profiles={profiles}
          />
        </Card>
      </div>
    )
  }
}

export default Conversation
