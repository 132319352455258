import * as Recharts from 'recharts'
import Card from '../../../components/card'
import strings from './analytics.strings.json'

const margins = { top: 24, left: 0, right: 0, bottom: 0 }
const colors = ['#8884d8', '#82ca9d', '#c98282']

const RenderCell = (_, index) => {
  const fill = colors[index]
  const key = `cell-${index}`
  return <Recharts.Cell key={key} fill={fill} />
}

export const Chart = ({ data }) => (
  <Card
    title={strings.sexes.title}
    subtitle={strings.sexes.subtitle}
    area="sexes"
  >
    <Recharts.ResponsiveContainer width="100%" height={400}>
      <Recharts.PieChart data={data} margin={margins}>
        <Recharts.Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={120}
          innerRadius={60}
          legendType="circle"
          label
        >
          {data.map(RenderCell)}
        </Recharts.Pie>
        <Recharts.Tooltip />
        <Recharts.Legend />
      </Recharts.PieChart>
    </Recharts.ResponsiveContainer>
  </Card>
)

const replaceKey = key => {
  return key
    .replace('woman', 'Women')
    .replace('man', 'Men')
    .replace('nonBinary', 'Non-binary')
}

export const formatData = sexes => {
  return Object.entries(sexes).map(([key, value]) => {
    return { value, name: replaceKey(key) }
  })
}
