import styles from './card.module.css'

const Card = ({ center, children, title, subtitle, area, ...props }) => {
  const className = [
    props.className ?? [],
    !!props.overflow ? styles.card : styles.cardOverflow,
  ]
    .flat()
    .join(' ')
  const capitalize = props.capitalize ?? true
  const titleStyle = capitalize ? styles.cardTitle : styles.cardNoCapitalize
  return (
    <div className={className} style={{ gridArea: area }}>
      <div className={styles.row}>
        <div className={styles.hidden}>
          {title && <div className={titleStyle}>{title}</div>}
          {subtitle && <div className={styles.cardSubTitle}>{subtitle}</div>}
        </div>
        {props.icon}
      </div>
      {center && <div className={styles.centerChild}>{children}</div>}
      {!center && <>{children}</>}
    </div>
  )
}

export default Card
