import * as http from '../http'

export const get = async () => {
  const res = await http.get('/api/choosables')
  return res
}

export const set = async (update, choosable) => {
  try {
    const body = { update, choosable }
    const res = await http.post('/api/admin/choosables/create', body)
    return res
  } catch (error) {
    return false
  }
}

export const avatars = {
  async get() {
    const res = await http.get(`/api/avatars`)
    return res || []
  },
  async add({ base64, category, mimeType }) {
    const body = { base64, category, mimeType }
    const res = await http.post('/api/admin/avatars/create', body)
    return res
  },
}
