import * as choosablesService from '../../services/choosables'

export const UPDATE_CHOOSABLES = 'UPDATE_CHOOSABLES'
export const UPDATE_CHOOSABLE = 'UPDATE_CHOOSABLE'
export const UPDATE_AVATARS = 'UPDATE_AVATARS'

const updateChoosables = choosables => {
  return { type: UPDATE_CHOOSABLES, choosables }
}

const avatars = () => async dispatch => {
  const avatars = await choosablesService.avatars.get()
  await dispatch({ type: UPDATE_AVATARS, avatars })
}

const addAvatar = (category, file) => async dispatch => {
  const mimeType = file.type
  const reader = new FileReader()
  const res = new Promise(solve => reader.addEventListener('load', solve))
  reader.readAsDataURL(file)
  await res
  const base64 = reader.result.replace(/data:.*;base64,/, '')
  await choosablesService.avatars.add({ mimeType, category, base64 })
}

const get = () => async dispatch => {
  const results = await choosablesService.get()
  await dispatch(updateChoosables(results))
  return results
}

const isCorrectTranslations = translations => {
  return translations.fr.length > 1 && translations.en.length > 1
}

const isChoosableOk = choosable => {
  const types = ['sex', 'quality', 'question', 'place', 'activity', 'music']
  const correctType = types.includes(choosable.type)
  const correctID = choosable?.id?.length > 1
  const correctTranslations = choosable.translations.singular
    ? isCorrectTranslations(choosable.translations.plural) &&
      isCorrectTranslations(choosable.translations.singular)
    : isCorrectTranslations(choosable.translations)
  const correctAge =
    choosable.type === 'age'
      ? choosable.maxAge > 0 &&
        choosable.minAge > 0 &&
        choosable.minAge < choosable.maxAge
      : true
  return correctType && correctID && correctTranslations && correctAge
}

const set = (update, choosable) => async dispatch => {
  if (isChoosableOk(choosable)) {
    const results = await choosablesService.set(update, choosable)
    await dispatch(get())
    return results
  }
}

export { get, set, avatars, addAvatar }
