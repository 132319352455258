import { useState, useEffect } from 'react'
import * as Duels from './duels'
import * as OnBoarding from './on-boarding'
import * as Sexes from './sexes'
import * as Archives from './archives'
import firebase from '../../../firebase'
import styles from './analytics.module.css'
import dummy from './dummy.data.json'

const readData = async setData => {
  if (process.env.NODE_ENV === 'development') {
    setData(dummy)
  } else {
    const doc = id => firebase.firestore().collection('analytics').doc(id)
    const extractData = doc => doc.data() ?? {}
    const toData = id => doc(id).get().then(extractData)
    const promises = [
      toData('duels'),
      toData('on-boarding'),
      toData('profiles-sexes'),
      toData('archives'),
    ]
    const [duels, onBoarding, sexes, archives] = await Promise.all(promises)
    setData({ duels, onBoarding, sexes, archives })
  }
}

const useAnalytics = () => {
  const emptyState = { duels: {}, onBoarding: {}, sexes: {}, archives: {} }
  const [data, setData] = useState(emptyState)
  useEffect(() => {
    readData(setData)
  }, [])
  return data
}

const Analytics = () => {
  const { duels, onBoarding, sexes, archives } = useAnalytics()
  const comp = ([a], [b]) => b.localeCompare(a)
  const lastDay = Object.entries(onBoarding).sort(comp)?.[0]?.[1]
  const lastSex = Object.entries(sexes).sort(comp)?.[0]?.[1]
  const lastArchives = Object.entries(archives).sort(comp)?.[0]?.[1]
  return (
    <div className={styles.layout}>
      <Duels.Chart data={Duels.formatData(duels)} />
      {lastDay && <OnBoarding.Chart data={OnBoarding.formatData(lastDay)} />}
      {lastSex && <Sexes.Chart data={Sexes.formatData(lastSex)} />}
      {lastArchives && (
        <Archives.Chart data={Archives.formatData(lastArchives)} />
      )}
    </div>
  )
}

export default Analytics
