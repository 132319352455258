import { useSelector } from 'react-redux'
import Card from '../card'
import Image from '../image'
import * as selectors from '../../store/selectors'
import strings from './avatars.strings.json'
import styles from './avatars.module.css'
import cross from './cross.svg'

const getTitle = ({ choose, dog }) => {
  if (choose) {
    return strings.avatars.choose
  } else if (dog) {
    return strings.avatars.dogs
  } else {
    return strings.avatars.cats
  }
}

const DisplayAvatars = props => {
  const { choose, className, dog, avatars, onClick, selected } = props
  const token = useSelector(selectors.token)
  return (
    <Card
      className={className}
      title={getTitle({ choose, dog })}
      subtitle={strings.avatars.subtitle}
    >
      <div className={styles.avatarsCard}>
        {avatars.map(({ id, category }) => {
          const cl = selected === id ? styles.selectedImage : styles.wrapImage
          const click = () => onClick && onClick(id)
          return (
            <div key={id} className={cl} onClick={click}>
              <Image
                className={styles.image}
                imgClassName={styles.avatarImage}
                url={`/avatars/${category}/${id}`}
                headers={{ Authorization: `Bearer ${token}` }}
              />
              <div className={styles.contextualActions}>
                <img
                  onClick={() => {}}
                  className={styles.deleteCross}
                  src={cross}
                  alt="cross"
                />
              </div>
            </div>
          )
        })}
      </div>
    </Card>
  )
}

export default DisplayAvatars
