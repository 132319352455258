import * as http from '../http'

const all = async () => {
  const res = await http.get('/api/admin/moderators')
  return res
}

const remove = async id => {
  const res = await http.del(`/api/admin/moderators/${id}`)
  return res
}

const add = async ({ email, claim }) => {
  const body = { email, claim }
  const res = await http.post('/api/admin/moderators/create', body)
  return res
}

export { all, remove, add }
