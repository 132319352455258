import firebase from '../firebase'

const configs = () => firebase.firestore().collection('configs')
const duels = () => configs().doc('duels')

export const updateMaxDuels = async max => await duels().update({ max })
export const updateSkipDuels = async skip => await duels().update({ skip })

export const get = async () => {
  const doc = await duels().get()
  const { max, skip } = doc.data()
  return { maxDuels: max, skipDuels: skip }
}
