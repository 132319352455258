import firebase from '../firebase'
// import * as http from '../http'

const supports = () => firebase.firestore().collection('supports')
const support = id => supports().doc(id)

const byDescendingDates = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1
  } else if (a.createdAt === b.createdAt) {
    return 0
  } else {
    return 1
  }
}

const fromFirestore = doc => {
  const data = doc.data()
  const id = doc.id
  const createdAt = data.createdAt.toDate()
  return { id, ...data, createdAt }
}

export const all = async () => {
  const { docs } = await supports().where('archived', '!=', true).get()
  return docs.map(fromFirestore).sort(byDescendingDates)
}

export const archive = async id => {
  const archivedAt = firebase.firestore.FieldValue.serverTimestamp()
  const updatedAt = archivedAt
  await support(id).update({ archived: true, archivedAt, updatedAt })
}

export const unarchive = async id => {
  const archivedAt = firebase.firestore.FieldValue.delete()
  const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
  await support(id).update({ archived: false, archivedAt, updatedAt })
}
