import { useState, useEffect } from 'react'
import * as Router from 'react-router-dom'
import firebase from '../../firebase'
import Spacer from '../spacer'
import styles from './sidebar.module.css'
import interrogationPoint from './interrogation-point.svg'
import duels from './duels.svg'
import messaging from './messaging.svg'
import logo from './logo.svg'
import cops from './cops.svg'
import avatars from './avatars.svg'
import users from './users.svg'
import configs from './configs.svg'
import likes from './likes.svg'
import support from './support.svg'
import menu from './menu.svg'
import goodbye from './goodbye.svg'
import charts from './charts.svg'

const NavLink = ({ title, to, icon }) => (
  <Router.NavLink to={to} className={styles.link}>
    <img className={styles.linkIcon} src={icon} alt={title} />
    {title}
  </Router.NavLink>
)

const SideBar = () => {
  const [opened, setOpened] = useState(false)
  const onClick = () => firebase.auth().signOut()
  const drawerClassName = opened
    ? styles.drawerWrapperOpened
    : styles.drawerWrapperClosed
  useEffect(() => {
    if (opened) {
      const [body] = document.getElementsByTagName('body')
      body.setAttribute('style', 'overflow: hidden; position: relative;')
      return () => body.removeAttribute('style')
      // window.addEventListener('scroll', fun)
      // return () => window.removeEventListener('scroll', fun)
    }
  }, [opened])
  return (
    <div className={styles.sidebar}>
      <img className={styles.logo} src={logo} alt="Courtisan" />
      <NavLink to="/duels" title="Duels" icon={duels} />
      <NavLink to="/likes" title="Likes" icon={likes} />
      <NavLink to="/conversations" title="Conversations" icon={messaging} />
      <NavLink to="/choosables" title="Choosables" icon={interrogationPoint} />
      <NavLink to="/moderators" title="Moderators" icon={cops} />
      <NavLink to="/manage-avatars" title="Avatars" icon={avatars} />
      <NavLink to="/users" title="Users" icon={users} />
      <NavLink to="/supports" title="Support" icon={support} />
      <NavLink to="/configs" title="Configurations" icon={configs} />
      <NavLink to="/analytics" title="Analytics" icon={charts} />
      <div className={styles.flex} />
      <button onClick={onClick} className={styles.link}>
        <img className={styles.linkIcon} src={goodbye} alt="Goodbye" />
        Disconnect
      </button>
      <button className={styles.buttonMenu} onClick={() => setOpened(s => !s)}>
        <img className={styles.linkIcon} src={menu} alt="Menu" />
      </button>
      <div className={drawerClassName}>
        <div className={styles.drawer}>
          <div className={styles.drawerSticky} onClick={() => setOpened(false)}>
            <Spacer size={12} />
            <NavLink to="/duels" title="Duels" icon={duels} />
            <NavLink to="/likes" title="Likes" icon={likes} />
            <NavLink
              to="/conversations"
              title="Conversations"
              icon={messaging}
            />
            <NavLink to="/moderators" title="Moderators" icon={cops} />
            <NavLink to="/supports" title="Support" icon={support} />
            <NavLink to="/analytics" title="Analytics" icon={charts} />
            <NavLink to="/configs" title="Configurations" icon={configs} />
            <div className={styles.flex} />
            <button onClick={onClick} className={styles.link}>
              <img className={styles.linkIcon} src={goodbye} alt="Goodbye" />
              Disconnect
            </button>
            <Spacer size={12} />
          </div>
        </div>
        <div
          className={styles.drawerClicker}
          onClick={() => setOpened(false)}
        />
      </div>
    </div>
  )
}

export default SideBar
