import { createContext, useState, useEffect, useContext } from 'react'
import Card from '../card'
import Button from '../button'
import Spacer from '../spacer'
import styles from './modal.module.css'

const Context = createContext()

export const useModal = () => useContext(Context)

const renderButtons = ({ start, content }) => {
  return (acc, { title, onClick: on, ...button }, idx) => {
    const onClick = () => {
      start(false)
      content?.resolve?.(button.value)
      on?.()
    }
    const spacer = idx === 0 ? [] : [<Spacer key={idx + 10000} size={12} />]
    const but = <Button key={idx} title={title} onClick={onClick} {...button} />
    return [...acc, ...spacer, but]
  }
}

const useModalState = rootRef => {
  const [started, setStarted] = useState(false)
  const [visible, setVisible] = useState(false)
  // eslint-disable-next-line
  const [blur, setBlur] = useState(0)
  const [opacity, setOpacity] = useState(0)
  const [content, setContent] = useState()
  useEffect(() => {
    // Dear future me, please remove the filter root when backdrop-filter
    //   transition are optimized. I hope you went to Japan.
    if (started) {
      setVisible(true)
      setTimeout(() => {
        // setBlur(40)
        if (rootRef.current) rootRef.current.style.filter = 'blur(40px)'
        setOpacity(1)
      }, 10)
    } else {
      setTimeout(() => {
        setVisible(false)
        setContent()
      }, 200)
      // setBlur(0)
      if (rootRef.current) rootRef.current.style.filter = 'blur(0px)'
      setOpacity(0)
    }
  }, [started, rootRef])
  const open = value =>
    new Promise(resolve => {
      setContent({ value, resolve })
      setStarted(true)
    })
  return { visible, blur, opacity, content, open, start: setStarted }
}

export const Provider = ({ children, rootRef }) => {
  const { visible, blur, opacity, content, open, start } =
    useModalState(rootRef)
  const buttons = content?.value?.buttons ?? []
  return (
    <Context.Provider value={{ open }}>
      {visible && (
        <div
          onClick={() => start(false)}
          className={styles.sureModal}
          style={{ backdropFilter: `blur(${blur}px)`, opacity }}
        >
          <div onClick={event => event.stopPropagation()}>
            <Card
              title={content?.value?.title}
              subtitle={content?.value?.subtitle}
            >
              <div className={styles.cardBody}>
                {content?.value?.content}
                <Spacer size={48} />
                <div className={styles.buttonWrapper}>
                  {buttons.reduce(renderButtons({ start, content }), [])}
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
      {children}
    </Context.Provider>
  )
}
