import { useState } from 'react'

const useColorTheme = () => {
  const dark = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [isDark, setIsDark] = useState(dark)
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', e => setIsDark(e.matches))
  return isDark ? 'dark' : 'white'
}

export { useColorTheme }
