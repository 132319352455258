import { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import SideBar from '../components/sidebar'
import NavBar from '../components/navbar'
import Duels from './connection/duels'
import Duel from './connection/duel'
import Conversations from './connection/conversations'
import Conversation from './connection/conversation'
import Choosables from './connection/choosables'
import Moderators from './connection/moderators'
import Avatars from './connection/avatars'
import Users from './connection/users'
import FakeUser from './connection/fake-user'
import Likes from './connection/likes'
import Like from './connection/like'
import Configurations from './connection/configs'
import Support from './connection/support'
import Analytics from './connection/analytics'
import { useConversations } from '../helpers/conversations'
import { useUserRedirect } from '../helpers/user'
import * as Size from '../helpers/body'
import styles from './connection.module.css'
import strings from './connection.strings.json'

const Title = ({ history, ...props }) => {
  const titles = () => {
    const { pathname } = history.location
    switch (pathname.split('/')[1]) {
      case 'likes':
        return [strings.likes.title, strings.likes.subtitle]
      case 'duels':
        return [strings.duels.title, strings.duels.subtitle]
      case 'conversations':
        return [strings.conversations.title, strings.conversations.subtitle]
      case 'choosables':
        return [strings.choosables.title, strings.choosables.subtitle]
      case 'moderators':
        return [strings.moderators.title, strings.moderators.subtitle]
      case 'manage-avatars':
        return [strings.avatars.title, strings.avatars.subtitle]
      case 'users':
        return [strings.users.title, strings.users.subtitle]
      case 'fake-user':
        return [strings.fakeUser.title, strings.fakeUser.subtitle]
      case 'analytics':
        return [strings.analytics.title, strings.analytics.subtitle]
      case 'configs':
        return [strings.configs.title, strings.configs.subtitle]
      case 'supports':
        return [strings.supports.title, strings.supports.subtitle]
      default:
        return [strings.errors.title, strings.errors.subtitle]
    }
  }
  const [title, subTitle] = titles()
  return (
    <Fragment>
      <h1
        className={styles.title}
        ref={props.titleRef}
        style={{ top: props.titleTop }}
      >
        {title}
      </h1>
      <h2
        className={styles.subTitle}
        style={{ top: props.subtitleTop }}
        ref={props.subtitleRef}
      >
        {subTitle}
      </h2>
    </Fragment>
  )
}

const NotFound = () => (
  <div className={styles.notFoundWrapper}>
    <div className={styles.notFoundAlign}>
      <code className={styles.four0Four}>404</code>
      <br />
      <code className={styles.notFoundText}>Not found</code>
    </div>
  </div>
)

const Connection = ({ history }) => {
  useUserRedirect()
  useConversations()
  const params = Size.useNavbarHeight(history.pathname)
  return (
    <Fragment>
      <SideBar />
      <NavBar />
      <Size.Provider value={params.totalHeight}>
        <div className={styles.mainSection}>
          <Title history={history} {...params} />
          <div className={styles.body}>
            <div className={styles.bodyWrapper}>
              <Switch>
                <Route path="/likes" exact component={Likes} />
                <Route path="/likes/:id" component={Like} />
                <Route path="/duels" exact component={Duels} />
                <Route path="/duels/:id" component={Duel} />
                <Route path="/conversations" exact component={Conversations} />
                <Route path="/conversations/:cid" component={Conversation} />
                <Route path="/choosables" component={Choosables} />
                <Route path="/moderators" component={Moderators} />
                <Route path="/manage-avatars" component={Avatars} />
                <Route path="/users" component={Users} />
                <Route path="/fake-user" component={FakeUser} />
                <Route path="/analytics" component={Analytics} />
                <Route path="/configs" component={Configurations} />
                <Route path="/supports" component={Support} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
        </div>
      </Size.Provider>
    </Fragment>
  )
}

export default Connection
