import {
  UPDATE_DUELS,
  UPDATE_DUEL,
  UPDATE_SINGLE_MESSAGE,
  UPDATE_WINNER_DUEL,
  UPDATE_CONVERSATION,
} from '../actions/duels'
import { STARTED, DELIBERATION_IN_PROGRESS, ENDED } from '../../services/duels'

const initialState = []

const updateDuelWhere = (duels, did, updater) => {
  const old = duels.find(d => d.id === did)
  const duel = updater(old)
  const index = duels.findIndex(d => d.id === did)
  const beginning = duels.slice(0, index)
  const end = duels.slice(index + 1, duels.length)
  return [...beginning, duel, ...end]
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DUELS:
      return action.duels
    case UPDATE_DUEL: {
      const { uid, did } = action
      return updateDuelWhere(state, did, old => {
        const competitors = [...old.competitors, uid]
        const updatedAt = new Date()
        return { ...old, state: STARTED, competitors, updatedAt }
      })
    }
    case UPDATE_SINGLE_MESSAGE: {
      const { did, uid, message: content } = action
      return updateDuelWhere(state, did, old => {
        const d = new Date()
        const message = { content, uid, createdAt: d }
        const messages = [...old.messages, message]
        const isFinished = messages.length === old.competitors.length
        const state = isFinished ? DELIBERATION_IN_PROGRESS : old.state
        return { ...old, messages, state, updatedAt: d }
      })
    }
    case UPDATE_WINNER_DUEL: {
      const { did, uid } = action
      return updateDuelWhere(state, did, old => {
        const state = ENDED
        const updatedAt = new Date()
        return { ...old, winner: uid, state, updatedAt }
      })
    }
    case UPDATE_CONVERSATION: {
      const { did } = action
      return updateDuelWhere(state, did, old => {
        const conversation = true
        const updatedAt = new Date()
        return { ...old, conversation, updatedAt }
      })
    }
    default:
      return state
  }
}

export default reducer
