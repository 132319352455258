import * as profilesService from '../../services/profiles'

export const UPDATE_PROFILE = 'UPDATE_PROFILE'

const updateProfile = profile => {
  return { type: UPDATE_PROFILE, profile }
}

const get = id => async dispatch => {
  const profile = await profilesService.get(id)
  await dispatch(updateProfile(profile))
  return profile
}

export { get }
