export const choosables = state => state.choosables
export const token = state => state.settings.token
export const search = state => state.miscellaneous.search
export const conversations = state => state.conversations.conversations
export const moderators = state => state.settings.moderators

export const configs = state => {
  const { configs } = state
  return configs
}

export const users = state => {
  const { users, user, token, disabled } = state.settings
  return { users, user, token, disabled }
}

export const newDuel = state => {
  const { choosables, settings } = state
  const { token } = settings
  return { choosables, token }
}

export const duels = state => {
  const { duels, choosables } = state
  return { duels, choosables }
}

export const likes = state => {
  const { likes } = state
  return likes
}

export const like = id => state => {
  const { likes } = state
  return likes.find(l => l.id === id)
}

export const duel = id => {
  return state => {
    const { duels, choosables, settings } = state
    const { token } = settings
    const d = duels.find(duel => duel.id === id)
    if (d) {
      const uids = [...d.competitors, d.wooed]
      const profiles = uids.reduce((profiles_, uid) => {
        const profile = state.profiles[uid]
        if (profile) {
          return { ...profiles_, [profile.id]: profile }
        } else {
          return profiles_
        }
      }, {})
      return { duel: d, choosables, profiles, token }
    } else {
      return { duel: d, choosables, profiles: {}, token }
    }
  }
}

export const conversation = id => state => {
  if (id) {
    const { token } = state.settings
    const conversation = state.conversations.conversations[id]
    const messages = state.conversations.messages[id]
    const profiles = (conversation?.users ?? []).reduce(
      (acc, user) => ({ ...acc, [user]: state.profiles[user] }),
      {},
    )
    return { conversation, messages, profiles, token }
  } else {
    return null
  }
}
