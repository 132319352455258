import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as selectors from '../../../store/selectors'
import { Input, Select } from '../../../components/inputs'
import Card from '../../../components/card'
import Loader from '../../../components/loader'
import Button from '../../../components/button'
import NestedList from '../../../components/nested-list'
import { useBodySize } from '../../../helpers/body'
import * as actions from '../../../store/actions/choosables'
import strings from './choosables.strings.json'
import styles from './choosables.module.css'

const selectAnOption = { id: '', translations: { fr: strings.option.select } }

const metadataTitles = ({ type }) => {
  switch (type) {
    case 'distance':
      return [strings.distances.title, strings.distances.subtitle]
    default:
      return null
  }
}

const QuestionMetadataContent = ({ question, edit, ...props }) => {
  switch (question.type) {
    case 'distance':
      const { distance } = question
      return (
        <Input
          disabled={!props.editable}
          label={strings.distances.label}
          value={distance}
          placeholder="123"
          type="number"
          onChange={distance => edit({ ...question, distance })}
        />
      )
    default:
      return null
  }
}

const QuestionMetadata = props => {
  const titles = metadataTitles(props.question)
  if (!titles) {
    return null
  } else {
    const [title, subtitle] = titles
    return (
      <Card area="metadata" title={title} subtitle={subtitle}>
        <QuestionMetadataContent {...props} />
      </Card>
    )
  }
}

const TranslationFields = ({ translations, editable, onChange }) => {
  return Object.entries(translations).map(([key, value]) => (
    <Input
      key={key}
      label={strings.labels.languages[key]}
      value={value}
      disabled={!editable}
      onChange={onChange(key)}
    />
  ))
}

const Translations = ({ question, edit, ...props }) => {
  const metadataTypes = ['distance']
  const isMetadata = metadataTypes.includes(question.type)
  const singularChange = key => value => {
    const singular = { ...question.translations.singular, [key]: value }
    const translations = { ...question.translations, singular }
    edit({ ...question, translations })
  }
  const pluralChange = key => value => {
    const plural = { ...question.translations.plural, [key]: value }
    const translations = { ...question.translations, plural }
    edit({ ...question, translations })
  }
  const onChange = key => value => {
    const translations = { ...question.translations, [key]: value }
    edit({ ...question, translations })
  }
  return (
    <Card
      area={isMetadata ? 'translations' : 'metadata'}
      title={strings.translations.title}
      subtitle={strings.translations.subtitle}
    >
      {question.translations.plural ? (
        <Fragment>
          <div className={styles.translationSubHeader}>Singular</div>
          <TranslationFields
            translations={question.translations.singular}
            editable={props.editable}
            onChange={singularChange}
          />
          <div className={styles.translationSubHeader}>Plural</div>
          <TranslationFields
            translations={question.translations.plural}
            editable={props.editable}
            onChange={pluralChange}
          />
        </Fragment>
      ) : (
        <TranslationFields
          translations={question.translations}
          editable={props.editable}
          onChange={onChange}
        />
      )}
    </Card>
  )
}

const possibleCategories = addPlaceholder => [
  addPlaceholder ? selectAnOption : null,
  { id: 'sex', translations: { fr: 'Sex' } },
  { id: 'distance', translations: { fr: 'Distance' } },
  { id: 'quality', translations: { fr: 'Quality' } },
  { id: 'question', translations: { fr: 'Question' } },
  { id: 'activity', translations: { fr: 'Activité' } },
  { id: 'music', translations: { fr: 'Musique' } },
  { id: 'place', translations: { fr: 'Place' } },
]

const toID = str => {
  return str.replace(/[?.]/g, '').trim().replace(/ /g, '-').toLowerCase()
}

const CategoryCard = ({ isCreate, question, edit, id }) => {
  if (isCreate) {
    const trans = { fr: '', en: '' }
    const multipleTranslations = {
      plural: trans,
      singular: trans,
    }
    return (
      <Card
        area="id"
        title={strings.categories.title}
        subtitle={strings.categories.subtitle}
      >
        <Select
          values={possibleCategories(!question.type).filter(t => t)}
          label={strings.categories.label}
          value={question.type}
          onChange={type => {
            const translations = type === 'sex' ? multipleTranslations : trans
            edit({ id: '', translations, type })
          }}
        />
      </Card>
    )
  } else {
    return (
      <Card
        area="id"
        title={id}
        subtitle={
          <Fragment>
            {strings.categories.content} <code>{id}</code>
          </Fragment>
        }
      />
    )
  }
}

const ChoosableIDContent = ({ isCreate, question, edit }) => {
  if (!isCreate) {
    return null
  } else {
    const editId = id => edit({ ...question, id })
    const id = () =>
      toID(
        question.translations?.en ??
          question.translations?.singular.en ??
          question.translations?.plural?.en ??
          '',
      )
    const generateId = () => edit({ ...question, id: id() })
    return (
      <Fragment>
        <Input
          label={strings.ids.input.label}
          placeholder={strings.ids.input.placeholder}
          value={question.id}
          onChange={editId}
        />
        <div style={{ padding: 12 }} />
        <Button
          title={strings.buttons.generateId}
          primary
          onClick={generateId}
        />
      </Fragment>
    )
  }
}

const ChoosableQuestion = ({ id, ...props }) => {
  const { editable, cancel, edit, save } = props
  const onClick = editable ? save : () => edit(props.question)
  const question = editable || props.question
  return (
    <div className={styles.choosableLayout}>
      <div className={styles.choosableButtons}>
        {editable && (
          <Fragment>
            <Button title={strings.buttons.cancel} warning onClick={cancel} />
            <div style={{ padding: 12 }} />
          </Fragment>
        )}
        <Button
          title={editable ? strings.buttons.save : strings.buttons.edit}
          onClick={onClick}
          primary={!editable}
          secondary={editable}
        />
      </div>
      <CategoryCard {...props} question={question} id={id} />
      <Card
        area="title"
        title={strings.ids.title}
        subtitle={
          !props.isCreate ? <code>{question.id}</code> : strings.ids.subtitle
        }
      >
        <ChoosableIDContent {...props} question={question} />
      </Card>
      <QuestionMetadata {...props} question={question} />
      <Translations {...props} question={question} />
    </div>
  )
}

const findQuestion = (choosables, selected) => {
  const findSelected = ({ id }) => selected === id
  const category = Object.entries(choosables).find(([_key, value]) => {
    const values = value || []
    return values.find(findSelected)
  })
  if (category) {
    const [id, questions] = category
    const question = questions.find(findSelected)
    if (question) {
      return [id, question]
    }
  }
  return []
}

const ChoosablesSelector = ({ choosables, ...props }) => {
  const [id, question] = findQuestion(choosables, props.selected)
  if (props.isCreate) {
    return <ChoosableQuestion choosables={choosables} {...props} />
  } else if (id) {
    return (
      <ChoosableQuestion
        id={id}
        question={question}
        choosables={choosables}
        {...props}
      />
    )
  } else {
    return (
      <Card center className={styles.body}>
        <Loader />
      </Card>
    )
  }
}

const fetchChoosables = async (dispatch, setSelected) => {
  const results = await dispatch(actions.get())
  const [fst] = Object.values(results)
  if (fst) {
    const [question] = fst
    question && setSelected(question.id)
  }
}

const useChoosables = dispatch => {
  const choosables = useSelector(selectors.choosables)
  const [selected, setSelected] = useState()
  useEffect(() => {
    if (!selected) {
      fetchChoosables(dispatch, setSelected)
    }
  }, [dispatch, selected, setSelected])
  return { selected, setSelected, choosables }
}

const useEditable = dispatch => {
  const emptyEdition = { create: false, choosable: null }
  const translations = { fr: '', en: '' }
  const [edition, setEdition] = useState(emptyEdition)
  const editable = edition.choosable
  const isCreate = edition.create
  const cancel = () => setEdition(emptyEdition)
  const edit = q => setEdition({ ...edition, choosable: { ...q } })
  const create = () =>
    setEdition({ create: true, choosable: { id: '', translations } })
  const save = async () => {
    const result = await dispatch(actions.set(!isCreate, editable))
    if (result) {
      cancel()
      toast.success(strings.toasts.success)
    } else {
      toast.error(strings.toasts.error)
    }
  }
  return { editable, edit, cancel, save, create, isCreate }
}

const Choosables = () => {
  const dispatch = useDispatch()
  const editValues = useEditable(dispatch)
  const values = useChoosables(dispatch)
  const { avatars, ...choosables } = values.choosables
  const bodySize = useBodySize(24)
  return (
    <div className={styles.layout} style={{ height: bodySize }}>
      <Button
        title={strings.buttons.new}
        primary
        disabled={editValues.isCreate}
        onClick={editValues.create}
      />
      <NestedList
        values={choosables}
        disabled={editValues.editable}
        {...values}
      />
      <ChoosablesSelector {...editValues} {...values} />
    </div>
  )
}

export default Choosables
