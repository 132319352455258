import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import * as selectors from '../../../store/selectors'
import * as actions from '../../../store/actions/settings'
import Card from '../../../components/card'
import Loader from '../../../components/loader'
import Button from '../../../components/button'
import Table from '../../../components/table'
import Image from '../../../components/image'
import { useModal } from '../../../components/modal'
import { useBodySize } from '../../../helpers/body'
import strings from './users.strings.json'
import styles from './users.module.css'

const fields = [
  'picture',
  'email',
  'uid',
  'name',
  'enabled',
  'signUp',
  'lastSignIn',
  'actions',
]

const Action = ({ uid, disabled = false }) => {
  const modal = useModal()
  const dispatch = useDispatch()
  const onClick = async () => {
    const result = await modal.open({
      title: strings.modal.title,
      subtitle: strings.modal.subtitle,
      content: strings.modal.content,
      buttons: [
        { title: strings.modal.nevermind, primary: true, value: false },
        { title: strings.modal.sure, info: true, value: true },
      ],
    })
    if (result) {
      const res = await dispatch(actions.disableUser(uid))
      if (res) {
        toast.success('User successfully disabled')
      } else {
        toast.error('Error, try again later')
      }
    }
  }
  if (!disabled) {
    return <Button title="Disable user" onClick={onClick} warning />
  } else {
    return null
  }
}

const Picture = ({ token, uid, pic }) => {
  if (!pic) return <div></div>
  return (
    <Image
      url={`/pictures/${uid}/${pic}`}
      headers={{ Authorization: `Bearer ${token}` }}
      imgClassName={styles.imgClassName}
      className={styles.imageWrapper}
    />
  )
}

const toTableData = (token, data) => {
  const { stats, profile, metadata } = data
  const name = profile.firstName
  const pic = profile.pics[0]
  const disabled = stats?.disabled || metadata?.disabled || false
  const email =
    metadata?.email ||
    metadata?.providerData?.reduce((acc, val) => acc || val.email, '') ||
    null
  const picture = <Picture token={token} uid={profile.id} pic={pic} />
  const actions = <Action uid={profile.id} disabled={disabled} />
  const meta = metadata?.metadata
  return {
    picture,
    name,
    email,
    uid: profile.id,
    id: profile.id,
    enabled: !disabled,
    lastSignIn: meta?.lastSignInTime ? new Date(meta?.lastSignInTime) : null,
    signUp: meta?.creationTime ? new Date(meta?.creationTime) : null,
    actions,
  }
}

const Users = () => {
  const { users, user, token, disabled } = useSelector(selectors.users)
  const search = useSelector(selectors.search)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(users.length === 0)
  const bodySize = useBodySize(24)
  useEffect(() => {
    if (user !== 'loading' && users.length === 0) {
      dispatch(actions.listUsers)
      setLoading(false)
    }
  }, [dispatch, users, user])
  const filtered = users.flatMap(user => {
    if (search.length === 0) {
      return [toTableData(token, user)]
    } else {
      const email = user.metadata?.email ?? ''
      const name = user.profile.firstName ?? ''
      const s = search.toLowerCase()
      const result =
        email.toLowerCase().includes(s) ||
        name.toLowerCase().includes(s) ||
        user.profile.id.includes(search)
      return result ? [toTableData(token, user)] : []
    }
  })
  return (
    <div className={styles.layout} style={{ maxHeight: bodySize }}>
      <Button
        disabled={process.env.NODE_ENV !== 'development'}
        title={strings.buttons.generate}
        primary
        onClick={() => dispatch(actions.fakeUsers())}
        className={styles.button}
      />
      <Button
        disabled={disabled}
        onClick={() => dispatch(actions.listUsers)}
        title={strings.buttons.loadMore}
        secondary
        className={styles.loadMore}
      />
      <Button
        title={strings.buttons.fakeUser}
        primary
        nav
        to="/fake-user"
        className={styles.fakeUser}
      />
      {loading && (
        <Card center area="table">
          <Loader />
        </Card>
      )}
      {!loading && <Table fields={fields} area="table" data={filtered} />}
    </div>
  )
}

export default Users
