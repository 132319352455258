import * as profiles from './profiles'

export const UPDATE_CONVERSATIONS = 'UPDATE_CONVERSATIONS'
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES'

export const updateConversations = conversations => {
  return async (dispatch, getState) => {
    const state = getState()
    const allConversations = Object.values(conversations)
    const actions = allConversations
      .flatMap(c => c.users)
      .filter(t => !state.profiles[t])
      .map(uid => dispatch(profiles.get(uid)))
    await Promise.all(actions)
    await dispatch({ type: UPDATE_CONVERSATIONS, conversations })
  }
}

export const updateMessages = (cid, messages) => {
  return { type: UPDATE_MESSAGES, cid, messages }
}
