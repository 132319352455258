import firebase from '../../firebase'
import logo from './google_logo.svg'
import styles from './google.module.css'

const onClick = async () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  const result = await firebase.auth().signInWithPopup(provider)
  return result
}

const SignIn = () => (
  <button className={styles.loginButton} onClick={onClick}>
    <img src={logo} alt="Google logo" />
    <span className={styles.loginText}>Sign in with Google</span>
  </button>
)

export default SignIn
