import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import firebase from '../../../firebase'
import Loader from '../../../components/loader'
import Button from '../../../components/button'
import Card from '../../../components/card'
import Image from '../../../components/image'
import Spacer from '../../../components/spacer'
import * as selectors from '../../../store/selectors'
import * as services from '../../../services/duels'
import { status } from '../../../services/profiles'
import * as helpers from '../../../helpers/likes'
import * as actions from '../../../store/actions/likes'
import styles from './like.module.css'
import strings from './like.strings.json'
import check from './check.png'

const postpone = ({ dispatch, history, id }) => {
  helpers.ignoreUser(id)
  dispatch(actions.postpone(id))
  if (history.length > 2) {
    history.goBack()
  } else {
    history.push('/likes')
  }
}

const toLocaleBirthday = birthday => {
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }
  return birthday.toLocaleString('fr-fr', options)
}

const optionsLastSeen = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
}

const Input = ({ message, active, profile, onClick }) => {
  const checkboxStyle = active ? styles.activeCheckbox : styles.inactiveCheckbox
  const imgStyle = { opacity: active ? 1 : 0 }
  return (
    <label className={styles.courtMessageWrapper} onClick={onClick}>
      <div className={styles.checkboxWrapper}>
        <div className={checkboxStyle}>
          <img
            src={check}
            alt="Check"
            className={styles.checkmark}
            style={imgStyle}
          />
        </div>
      </div>
      <div style={{ padding: 12 }} />
      <div className={styles.courtMessage}>
        <div className={styles.courtMessageName}>
          {profile?.firstName ?? strings.deleted}
        </div>
        <div className={styles.courtMessageContent}>{message.content}</div>
      </div>
    </label>
  )
}

const groupByQuestion = messages => {
  const grouped = messages.reduce((acc, val) => {
    const question = val.selectedQuestion
    const preexistingQuestions = acc[question] ?? []
    return { ...acc, [question]: [...preexistingQuestions, val] }
  }, {})
  return Object.entries(grouped)
}

const RenderLike = ({ id, profiles, likes, token, choosables, ...props }) => {
  const { onClick, history } = props
  const dispatch = useDispatch()
  const userProfile = profiles[id]
  const [questionToAsk, setQuestionToAsk] = useState()
  const [state, setState] = useState([])
  const { qualities, questions, sexes } = choosables
  // prettier-ignore
  const findById = id_ => ({ id }) => id === id_
  const quality = qualities.find(findById(userProfile.quality))
  const searchedQuality = qualities.find(findById(userProfile.searchedQuality))
  const selectedQuestion = questions.find(
    findById(userProfile.selectedQuestion),
  )
  const searchedGender = userProfile.searchedGender.flatMap(gender => {
    const g = sexes.find(findById(gender))
    return g ? [g] : []
  })
  const sex = sexes.find(findById(userProfile.sex))
  const { highAge } = userProfile
  const high = highAge === 80 ? highAge + '+' : highAge
  const groupedMessages = groupByQuestion(likes.messages)
  return (
    <div className={styles.likeGrid} style={{ paddingBottom: 24 }}>
      <Card
        area="profile"
        title={`#${id}`}
        subtitle={`Last seen ${likes.lastSeen.toLocaleString(
          'fr-FR',
          optionsLastSeen,
        )}`}
        capitalize={false}
      >
        {userProfile?.fake && (
          <div className={styles.fakePillWrapper}>
            <div className={styles.fakePill}>Fake</div>
          </div>
        )}
        <div className={styles.infos}>
          <div className={styles.infosText}>
            <div className={styles.name}>
              {userProfile?.firstName ?? strings.deleted}
            </div>
            <div className={styles.profileItem}>
              🙂 {quality?.translations?.fr}
            </div>
            <div className={styles.profileItem}>
              😍 {searchedQuality?.translations?.fr}
            </div>
            <div className={styles.profileItem}>
              🍿 {userProfile.favoriteMovie}
            </div>
            <div className={styles.profileItem}>
              🥢 {userProfile.favoriteMeal}
            </div>
            <div className={styles.profileItem}>
              🎂 {toLocaleBirthday(userProfile.birthday)}
            </div>
            <div className={styles.profileItem}>
              🍆 {sex?.translations?.plural?.fr}
            </div>
            <div className={styles.profileItem}>
              🔎{' '}
              {searchedGender
                .map(gender => gender.translations?.plural?.fr)
                .join(' & ')}
            </div>
            <div className={styles.profileItem}>
              😘 {[userProfile.lowAge, high].join(' - ')}
            </div>
            <div className={styles.profileItem}>
              ﹖ {selectedQuestion?.translations?.fr}
            </div>
          </div>
          <Image
            className={styles.imageWrapper}
            imgClassName={styles.image}
            url={`/pictures/${id}/${userProfile.pics[0]}`}
            headers={{ Authorization: `Bearer ${token}` }}
          />
        </div>
      </Card>
      <Card area="messages" title={strings.title} subtitle={strings.subtitle}>
        <div style={{ padding: 12 }} />
        {groupedMessages.length === 0 && (
          <div className={styles.noMessages}>{strings.noMessages}</div>
        )}
        {groupedMessages.length > 0 &&
          groupedMessages.map(([question, messages]) => {
            return (
              <div key={question} className={styles.questionMessageWrapper}>
                <div className={styles.questionMessage}>
                  {questions.find(findById(question))?.translations?.fr}
                </div>
                {messages.map((message, index) => {
                  const profile = profiles[message.author]
                  const active = state.includes(message.author)
                  return (
                    <Input
                      key={`${message.author}-${index}`}
                      message={message}
                      profile={profile}
                      active={active}
                      onClick={() => {
                        setState(s => {
                          const isAsked =
                            message.selectedQuestion === questionToAsk
                          if (!questionToAsk || isAsked) {
                            if (s.includes(message.author)) {
                              const result = s.filter(t => t !== message.author)
                              if (result.length === 0) setQuestionToAsk()
                              return result
                            } else {
                              setQuestionToAsk(message.selectedQuestion)
                              return [...s, message.author]
                            }
                          }
                          return s
                        })
                      }}
                    />
                  )
                })}
              </div>
            )
          })}
      </Card>
      <div className={styles.buttonIcon}>
        <div className={styles.actionButtons}>
          <Button
            title={strings.postpone}
            secondary
            onClick={() => postpone({ id, dispatch, history })}
          />
          <Spacer size={12} />
          <Button
            title={strings.delete}
            disabled={state.length === 0}
            warning
            onClick={() => {
              const containsAuthor = message => state.includes(message.author)
              const messages = likes.messages.filter(containsAuthor)
              dispatch(actions.removeMessages(id, messages))
              setState([])
              setQuestionToAsk()
              props.setLikes(likes => {
                const previousSize = likes.messages.length
                const messages = likes.messages.filter(like => {
                  return !state.includes(like.author)
                })
                const count =
                  likes.count - (previousSize - likes.messages.length)
                return { ...likes, count, messages }
              })
            }}
          />
        </div>
        <Button
          title={strings.letsFight}
          primary
          disabled={state.length !== 2}
          onClick={() => {
            onClick(state)
            setState([])
            setQuestionToAsk()
          }}
        />
      </div>
    </div>
  )
}

const fetcher = async id => {
  const likes = await firebase.firestore().collection('likes').doc(id).get()
  const data = likes.data()
  const lastSeen = data.lastSeen.toDate()
  const fake = data.fake ?? false
  const messages = data.messages.map(message => {
    const createdAt = message.createdAt.toDate()
    return { ...message, createdAt }
  })
  const uids = data.messages.map(m => m.author).concat([id])
  const profiles_ = await Promise.all(
    uids.map(async id => {
      const doc = await firebase
        .firestore()
        .collection('profiles')
        .doc(id)
        .get()
      const data = doc.data()
      const birthday = data.birthday.toDate()
      const s = await status(id)
      return data ? { ...data, id: doc.id, birthday, ...s } : null
    }),
  )
  const profiles = profiles_.reduce(
    (acc, t) => (t === null ? acc : { ...acc, [t.id]: t }),
    {},
  )
  const token = await firebase.auth().currentUser?.getIdToken()
  return { profiles, likes: { ...data, fake, messages, lastSeen }, token }
}

const Like = ({ match, history }) => {
  const choosables = useSelector(selectors.choosables)
  const { id } = match.params
  const [loader, setLoader] = useState(true)
  const [profiles, setProfiles] = useState(null)
  const [likes, setLikes] = useState(null)
  const [token, setToken] = useState(null)
  const dispatch = useDispatch()
  const removeCompetitors = competitors => {
    dispatch(actions.decrementCount({ id, competitors }))
    setLikes(like => {
      const count = like.count - competitors.length
      const messages = like.messages.filter(m => {
        const id = m.author
        return !competitors.includes(id)
      })
      return { ...like, count, messages }
    })
  }
  useEffect(() => {
    if (likes?.count <= 1) postpone({ dispatch, history, id })
  }, [likes, dispatch, history, id])
  useEffect(() => {
    fetcher(id).then(({ profiles, likes, token }) => {
      setLikes(likes)
      setProfiles(profiles)
      setToken(token)
      setLoader(false)
    })
  }, [id])
  if (loader) return <Loader />
  return (
    profiles &&
    likes &&
    token && (
      <RenderLike
        id={id}
        profiles={profiles}
        likes={likes}
        setLikes={setLikes}
        token={token}
        choosables={choosables}
        history={history}
        onClick={async competitors => {
          if (loader) return
          try {
            removeCompetitors(competitors)
            const res = await services.create({ wooed: id, competitors })
            if (res === null) throw new Error('Dammit')
            toast.success(strings.duelCreated)
          } catch (error) {
            toast.error(strings.internalError)
          } finally {
            setLoader(false)
          }
        }}
      />
    )
  )
}

export default Like
