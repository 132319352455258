export default [
  'Forrest Gump',
  'La Liste de Schindler',
  'La Ligne Verte',
  '12 hommes en colère ',
  'Le Parrain',
  'Les Évadés',
  'Le Roi Lion',
  'Pulp Fiction',
  'Gran Torino',
  'Gladiator',
  'Seven',
  'Interstellar',
  'Le Dictateur',
  'Parasite',
  'Fight Club',
  'Inception',
  'Les Affranchis',
  'Retour vers le futur',
  'Usual Suspects',
  'La Vie est belle',
  'Les Tuches',
  'Fast and Furious',
  'Adieu les cons',
  'Le Diner de cons',
  'Les Bronzés font du ski ',
  'Les Visiteurs',
  'Whiplash',
  'Lion',
  'American History X',
  "L' Intendant Sansho",
  'Joker',
  'Le Tombeau des lucioles',
  'Psychose',
  'Le Silence des agneaux',
  'Les Lumières de la ville',
  'Star Wars : Episode VI - Le Retour du Jedi',
  'Ordet',
  'Tu ne tueras point',
  'Million Dollar Baby',
  'Les Enfants Loups, Ame & Yuki',
  "Voyage au bout de l'enfer",
  'Le Voyage de Chihiro',
  'Les Sentiers de la gloire',
  'Apocalypse Now Final Cut',
  'Sherlock Junior',
  'Intouchables',
  'Indiana Jones et la Dernière Croisade',
  'Bohemian Rhapsody',
  'Le Conte de la princesse Kaguya',
  'Le Pianiste',
  'Princesse Mononoké',
  'Mommy',
  'Spider-Man : New Generation',
  '7. Koğuştaki Mucize',
  'Toy Story 3',
  'Il faut sauver le soldat Ryan',
  'La Vérité',
  "Les Aventuriers de l'Arche perdue",
  'Batman contre le fantôme masqué',
  'Fenêtre sur cour',
  'Alien, le huitième passager',
  'Full Metal Jacket',
  'Shutter Island',
  "Edward aux mains d'argent",
  'Barry Lyndon',
  'Jour de colère',
  'Sueurs froides',
  'Elephant Man',
  "Lettre d'une inconnue",
  'Wall-E',
  "La passion de Jeanne d'Arc",
  '1917',
  'Alabama Monroe',
  'Umberto D.',
  'Reservoir Dogs',
  "L' Argent de la vieille",
  "L' Inconnu",
  'Prisoners',
  "La Ruée vers l'or",
  'Harakiri',
  'La Grande Vadrouille',
  'Boulevard du crépuscule',
  'Capharnaüm',
  'Shining',
  'Baby Cart la saga',
  'Avengers: Infinity War',
  'Le Garçon et la Bête',
  'La Nuit du chasseur',
  'Togo',
  "Entre le ciel et l'enfer",
  'La Mort aux trousses',
  'Les Contes de la lune vague après la pluie',
  'Incendies',
  'Citizen Kane',
  'Laisse parler ton coeur',
  'La Barbe à papa',
  'Léon',
  'Sixième Sens',
  'Là-haut',
]
