import { useEffect, useRef } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import firebase from './firebase'
import store from './store'
import * as actions from './store/actions/settings'
import styles from './App.module.css'
import * as Modal from './components/modal'
import Home from './views/home'
import Connection from './views/connection'

const App = () => {
  useEffect(() => {
    return firebase.auth().onAuthStateChanged(user => {
      store.dispatch(actions.updateUser(user))
    })
  }, [])
  const navigationRef = useRef()
  const rootRef = useRef()
  return (
    <Provider store={store}>
      <Modal.Provider rootRef={rootRef}>
        <div className={styles.root} ref={rootRef}>
          <Router ref={navigationRef}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route component={Connection} />
            </Switch>
          </Router>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            newestOnTop
            pauseOnFocusLoss
            hideProgressBar={false}
            draggable
            pauseOnHover
          />
        </div>
      </Modal.Provider>
    </Provider>
  )
}

export default App
