import firebase from '../firebase'
import * as http from '../http'

export const get = async id => {
  const profile = await http.get(`/api/admin/profiles/${id}`)
  if (profile) {
    const birthday = new Date(profile.birthday)
    const searchedGender = [profile.searchedGender].flat()
    return { ...profile, birthday, searchedGender }
  } else {
    return null
  }
}

export const list = async page => {
  return await http.get(`/api/admin/users?page=${page}`)
}

export const fake = async () => {
  const fun = firebase.functions().httpsCallable('scripts-create-people')
  const results = await fun({ count: 10 })
  return results.data
}

export const createNewUser = async user => {
  const fun = firebase.functions().httpsCallable('scripts-create-user')
  const results = await fun(user)
  return results.data
}

export const newUid = async () => {
  const fun = firebase.functions().httpsCallable('scripts-create-newUser')
  const results = await fun()
  return results.data
}

export const pictures = {
  upload: async (uid, picture, type) => {
    const body = { uid, base64: picture, type }
    const res = await http.post('/api/admin/pictures/upload', body)
    return res
  },
}

export const disable = async uid => {
  const res = await http.del(`/api/admin/user/${uid}/disable`)
  return res
}

export const status = async uid => {
  const res = await http.get(`/api/admin/user/${uid}/status`)
  return res
}
