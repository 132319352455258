import { UPDATE_MAX_DUELS, UPDATE_SKIP_DUELS } from '../actions/configs'

const initialState = {
  maxDuels: null,
  skipDuels: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MAX_DUELS:
      const { maxDuels } = action
      return { ...state, maxDuels }
    case UPDATE_SKIP_DUELS:
      const { skipDuels } = action
      return { ...state, skipDuels }
    default:
      return state
  }
}

export default reducer
