const firebase = window.firebase

const functions = firebase.app().functions('europe-west1')

if (process.env.NODE_ENV === 'development') {
  functions.useEmulator('localhost', 5001)
}

firebase.functions = () => functions

export default firebase
