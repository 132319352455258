import { useState, useRef } from 'react'
import { useColorTheme } from '../../helpers/appearance'
import styles from './inputs.module.css'
import cloudWhite from './cloud-white.svg'
import cloudBlack from './cloud-black.svg'
import strings from './inputs.strings.json'

const onDragOver = event => event.preventDefault()

const toResult = ({ type, value, utc }) => {
  if (type === 'number') {
    return parseInt(value, 10)
  } else if (type === 'date') {
    const [year, month, day] = value.split('-').map(t => parseInt(t, 10))
    if (utc) {
      return new Date(Date.UTC(year, month - 1, day))
    } else {
      return new Date(year, month - 1, day)
    }
  } else {
    return value
  }
}

const onInputChange = ({ onChange, type, utc }) => {
  return event => {
    if (onChange) {
      const { value } = event.target
      onChange(toResult({ type, value, utc }))
    }
  }
}

const onInputKeyPress = ({ onCmdEnter }) => {
  return event => {
    if (event.keyCode === 13 && (event.metaKey || event.ctrlKey)) {
      onCmdEnter && onCmdEnter()
    }
  }
}

const prependZero = value => (value < 10 ? `0${value}` : value.toString())

const toInputValue = ({ type, value }) => {
  if (type === 'date') {
    const year = value.getFullYear()
    const month = value.getMonth() + 1
    const day = value.getDate()
    return [year, month, day].map(prependZero).join('-')
  } else {
    return value
  }
}

const Input = ({ label, placeholder, disabled, value, type, ...props }) => {
  const onChange = onInputChange({ ...props, type })
  const onKeyDown = onInputKeyPress({ ...props, type })
  const className = styles.input
  const p = { placeholder, onChange, onKeyDown, className, disabled }
  const val = toInputValue({ type, value })
  return (
    <label className={`${styles.label} ${props.labelClassName || ''}`}>
      <div className={styles.labelName}>{label}</div>
      {props.multiline ? (
        <textarea rows={props.rows ?? 20} value={val} {...p} />
      ) : (
        <input type={type ?? 'text'} value={val} {...p} />
      )}
    </label>
  )
}

const Label = props => {
  const colorTheme = useColorTheme()
  const [dropped, setDropped] = useState(false)
  const dropRef = useRef(0)
  const onDrop = async event => {
    event.preventDefault()
    const files = [...event.dataTransfer.files]
    if (files.length > 0) {
      props.onDrop(files)
    }
  }
  const onDragEnter = event => {
    event.preventDefault()
    dropRef.current += 1
    setDropped(true)
  }
  const onDragLeave = () => {
    dropRef.current -= 1
    if (dropRef.current === 0) {
      setDropped(false)
    }
  }
  return (
    <div
      className={styles.labelInput}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
    >
      <img
        src={colorTheme === 'white' ? cloudBlack : cloudWhite}
        alt="cloud"
        className={styles.inputImg}
      />
      {dropped ? strings.upload.dropped : strings.upload.label}
    </div>
  )
}

const File = ({ className, onChange, multiple }) => {
  return (
    <label className={className}>
      <Label onDrop={onChange} />
      <input
        multiple={multiple}
        type="file"
        className={styles.inputFile}
        onChange={event => onChange([...event.target.files])}
      />
    </label>
  )
}

const Select = ({ label, disabled, value, values, onChange }) => (
  <label className={styles.label}>
    <div className={styles.labelName}>{label}</div>
    <select
      className={styles.input}
      disabled={disabled}
      value={value}
      onChange={event => onChange && onChange(event.target.value)}
    >
      {values.map(value => (
        <option key={value.id} value={value.id}>
          {value?.translations?.fr ??
            value?.translations?.en ??
            value?.translations?.singular?.fr ??
            value?.translations?.singular?.en}
        </option>
      ))}
    </select>
  </label>
)

export { Select, Input, File }
