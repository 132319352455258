import { Fragment } from 'react'
import Card from '../card'
import Button from '../button'
import Spacer from '../spacer'
import arrow from './arrow.png'
import styles from './duel.module.css'
import strings from './duel.strings.json'

const createMessage = (message, index) => (
  <Message key={message.uid} message={message} left={index % 2 !== 0} />
)

const Messages = ({ duel }) => duel.messages.map(createMessage)
const Message = ({ message, left }) => (
  <div className={left ? styles.left : styles.right}>
    <div className={styles.messageContent}>{message.content}</div>
    <div className={styles.messageDate}>
      {message.createdAt.toLocaleString()}
    </div>
  </div>
)

const TimeMetadata = ({ value, title }) => {
  const day = value.toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
  const hour = value.toLocaleString('fr-FR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
  return (
    <div className={styles.metadataFlex}>
      <div className={styles.metadataTitle}>{title}</div>
      <div className={styles.metadataData}>
        Le {day} à {hour}
      </div>
    </div>
  )
}

const Duel = ({ duel, choosables, className, ...props }) => {
  const question = choosables.questions.find(({ id }) => id === duel.question)
  const title = duel.state.replace(/-/g, ' ')
  const subtitle = `#${duel.id}`
  const questionTitle = question?.translations?.fr ?? question?.translations?.en
  const to = `/duels/${duel.id}`
  const seeMore = props.seeMore ?? true
  return (
    <Card title={title} subtitle={subtitle} className={className}>
      <Spacer size={24} />
      <TimeMetadata title="Created At" value={duel.createdAt} />
      <Spacer size={6} />
      <TimeMetadata title="Updated At" value={duel.updatedAt} />
      <div className={styles.cardTitle}>{questionTitle}</div>
      <Messages duel={duel} />
      {seeMore && (
        <Fragment>
          <div className={styles.spacer} />
          <Button
            nav
            to={to}
            info
            className={styles.seeMore}
            title={strings.seeMore}
            icon={arrow}
            iconAlt={strings.arrowAltIcon}
          />
        </Fragment>
      )}
    </Card>
  )
}

export default Duel
